

const state = {
    cardioData: null
}

const getters = {
    getCardioData: (state) => {
        return state.cardioData;
    }
}

const actions = {
    saveCardioData(context, payload) {
        context.commit('saveCardioData', payload);
    },
    clearCardio(context){
        context.commit('clearCardio')
    }


}

const mutations = {
    saveCardioData(state, payload) {
        state.cardioData = payload;
    },
    clearCardio(state){
        state.cardioData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}