import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import authModule from './modules/auth/auth'
import userProgressModule from './modules/userProgress'
import personalDetailsModule from './modules/personalDetails'
import cardioModule from './modules/cardio'
import mentalhealth from './modules/mentalHealth'
import femaleHealth from './modules/femaleHealth'
import hypertensionModule from './modules/hypertension'
import diabetesModule from './modules/diabetes'
import cancerModule from './modules/cancer'
import hivModule from './modules/HIV'
import numbersModule from './modules/numbers'
import campaignModule from './modules/campaign'

const store = new Vuex.Store({
    modules: {
      authModule,
      userProgressModule,
      personalDetailsModule,
      cardioModule,
      mentalhealth,
      femaleHealth,
      hypertensionModule,
      diabetesModule,
      cancerModule,
      hivModule,
      numbersModule,
      campaignModule
    },

    strict: false
  })
  
  export default store;