<template>
  <div class="columns" style="margin-bottom: 20px">
    <div class="column">
      <p class="title is-6" style="margin-bottom: 3px">
        {{ title }}
      </p>
      <b-field type="is-primary">
        <p style="font-size: 13px">
          {{ capitalise }}
        </p>
      </b-field>
    </div>
    <div class="column is-three-quarters">
      <p style="font-size: 13px">
      Recommendations:<br />{{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    riskCat: String,
    message: String, //for binding
  },
  computed: {
    capitalise() {
      var risk_cat = this.riskCat[0].toUpperCase() + this.riskCat.substring(1);
      if (risk_cat == "High risk") {
        return "Increased risk";
      } else if (risk_cat == "High Risk") {
        return "Increased risk";
      }
      return risk_cat;
    },
  },
};
</script>

<style lang="scss" scoped >
p {
  white-space: pre-wrap;
}
// .riskText {
//   color: $primary;
// }
</style>
