import { ToastProgrammatic as toast } from "buefy";
var helpers = {
  mapJsonFields: (obj) => {
    const date = new Date(obj.date_of_birth.$date);
    const comp_date = new Date(obj.date_of_completion.$date);
    var arr = JSON.stringify(obj);

    for (var key in fieldMap) {
      arr = arr.replace(key.toString(), fieldMap[key]);
    }
    var newObj = JSON.parse(arr);
    newObj.dateOfBirth = date;
    newObj.dateOfCompletion = comp_date;
    return newObj;
  },
  successToast: (msg, duration) => {
    toast.open({
      duration: duration,
      message: msg,
      position: "is-bottom-right",
      type: "is-success",
      hasIcon: false,
    });
  },
  errorToast: (msg, duration) => {
    toast.open({
      duration: duration,
      message: msg,
      position: "is-bottom-right",
      type: "is-danger",
      hasIcon: false,
    });
  },
  warningToast: (msg, duration) => {
    toast.open({
      duration: duration,
      message: msg,
      position: "is-bottom-right",
      type: "is-warning",
      hasIcon: false,
    });
  },

  convertDateObject: (date) => {
    const dateStrings = [];
    if (date != null) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();

      dateStrings.push(dd);
      dateStrings.push(mm);
      dateStrings.push(yyyy);
    }
    return dateStrings;
  },

  // getHeaders() {
  //   return headers;
  // },

  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    var headerDict = {};

    for (var j = 0; j < headerArray.length; j++) {
      headerDict[headerArray[j]] = []; //for each header in dict, create and empty array to push values to
    }

    for (var i = 0; i < array.length; i++) {
      //loop through array of jsons to export
      for (var n = 0; n < headerArray.length; n++) {
        headerDict[headerArray[n]].push(""); //push an empty string, later to be replaced if that header has a value
      }

      var line = "";
      for (var index in array[i]) {
        if (index in headerDict) {
          var len = headerDict[index].length;
          if (array[i][index]["$date"]) {
            headerDict[index][len - 1] = this.convertDate(array[i][index]["$date"]);
          } else {
            headerDict[index][len - 1] = array[i][index];
          }
        }
        if (array[i][index] instanceof Object) {
          const obj = array[i][index];
          for (var key in obj) {
            if (obj[key] instanceof Object) {
              //deals with only 3 layers of nested json objects
              if (obj[key]["$date"]) {
                if (key in headerDict) {
                  headerDict[key][len - 1] = this.convertDate(obj[key]["$date"]);
                }

              } else {
                for (var k in obj[key]) {
                  if (k in headerDict) {
                    len = headerDict[k].length;
                    headerDict[k][len - 1] = obj[key][k];
                  }
                }
              }
            } else {
              var value = obj[key];

              if (index == "hypertension_data") {
                if (!(key in headerDict)) {
                  key = key + "_hyp";
                }
              }
              if (index == "cardio_data") {
                if (!(key in headerDict)) {
                  key = key + "_cardio";
                }
              }
              if (index == "diabetes_data") {
                if (!(key in headerDict)) {
                  key = key + "_diabetes";
                }
              }
              if (index == "cancer_data") {
                if (!(key in headerDict)) {
                  key = key + "_cancer";
                }
              }
              if (index == "hiv_data") {
                if (!(key in headerDict)) {
                  key = key + "_hiv";
                }
              }
              if (index == "tb_data") {
                if (!(key in headerDict)) {
                  key = key + "_tb";
                }
              }
              if (index == "sti_data") {
                if (!(key in headerDict)) {
                  key = key + "_sti";
                }
              }
              if (index == "numbers") {
                if (!(key in headerDict)) {
                  key = key + "_numbers";
                }
              }
              if (key in headerDict) {
                len = headerDict[key].length;
                headerDict[key][len - 1] = value;
              }
            }
          }
        }
      }
    }

    //console.log(headerDict);

    line = "";
    for (i = 0; i < headerArray.length; i++) {
      line = line + ";" + headerArray[i];
    }
    if (line.includes("\n") || line.includes("\r")) {

      line = line.replaceAll("\n", " ")
      line = line.replaceAll("\r", " ")
    }
    str = line + "\r\n";
    for (i = 0; i < headerDict["name"].length; i++) {
      line = "";
      for (var arr in headerDict) {
        line = line + ";" + headerDict[arr][i];
      }
      if (line.includes("\n") || line.includes("\r")) {
        line = line.replaceAll("\n", " ")
        line = line.replaceAll("\r", " ")
      }
      str += line + "\r\n";
    }
    return str;
  },

  exportCSVFile(items, fileTitle) {
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  convertHeaderString(headersString) {
    var split = headersString.split(',"');
    var headers = [];
    var header = "";
    var stringOut = "[";

    for (var s in split) {
      var subsplit = split[s].split(":");

      if (!split[s].includes("_data")) {
        header = subsplit[0].replace('"', "").replace("{", "");
      } else {
        header = subsplit[1].replace('"', "").replace("{", "");
      }
      stringOut = stringOut + '" , "' + header;

      headers.push(header);
    }

    return headers;
  },

  convertDate(dateInMillis, formatType) {
    var date = new Date(dateInMillis);
    const dateStrings = [];
    if (date != null) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();

      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      if (formatType == "english") {
        mm = months[date.getMonth()]
      }

      dateStrings.push(dd);
      dateStrings.push(mm);
      dateStrings.push(yyyy);
    }
    if (formatType == "english") {
      return dateStrings[0] + " " + dateStrings[1] + ", " + dateStrings[2];
    }
    return dateStrings[0] + "/" + dateStrings[1] + "/" + dateStrings[2];
  },

  // var itemsFormatted = [];

  // // format the data
  // itemsNotFormatted.forEach((item) => {
  //   itemsFormatted.push({
  //       model: item.model.replace(/,/g, ''), // remove commas to avoid errors,
  //       chargers: item.chargers,
  //       cases: item.cases,
  //       earphones: item.earphones
  //   });
  // });

  //exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function
};
//const headers2 = [];
// var headers = {
//   _id: "database id",
//   reference_num: "referenceNum",
//   name: "name",
//   surname: "surname",
//   fullname: "fullName",
//   id_number: "idNumber",
//   cellphone: "cellphone",
//   work_number: "workNumber",
//   date_of_completion: "date completed",
//   campaign_code: "campaign code",
//   date_of_birth: "DOB",
//   dob_string: "DOB String",
//   age: "age",
//   age_band: "age band",
//   gender: "gender",
//   height: "height",
//   weight: "weight",
//   fields_completed: "fields completed",
//   contact_consent: "contact consent",
//   pharmacy_consent: "pharmacy consent",
//   completed_by:"completed by",
//   stroke_or_heart_attack: "Stroke/Heart attack",
//   high_cholestrol: "High Cholestrol",
//   smoker: "smoker",
//   cardiovascular_score: "Cardio Score",
//   risk_cat: "risk cat",
//   message:"message",
//   nurse_task:"nurse task",
//   screening_type:"screening type",
//   notes: "notes",
//   diagnosed: "diagnosed",
//   on_medication: "on medication",
//   told_you_have_hypertension: "told you have hypertension",
//   family_history: "family history",
//   during_pregnancy: "during pregnancy",
//   hypertension_score: "hypertension score",
//   cancer_data: "cancer data",
//   hiv_data: "hiv data",
//   tb_data: "tb data",
//   sti_data: "sti data",
// };

var headerArray = [
  "id",
  "reference_num",
  "name",
  "surname",
  "fullname",
  "id_number",
  "cellphone",
  "work_number",
  "date_of_completion",
  "campaign_code",
  "date_of_birth",
  "dob_string",
  "age",
  "age_band",
  "gender",
  "fields_completed",
  "contact_consent",
  "pharmacy_consent",
  "completed_by",
  "stroke_or_heart_attack",
  "high_cholestrol",
  "smoker",
  "cardio_score",
  "risk_cat_cardio",
  "message_cardio",
  "nurse_task_cardio",
  "screening_type_cardio",
  "notes_cardio",
  "diagnosed_hyp",
  "on_medication_hyp",
  "told_you_have_hypertension",
  "family_history_hyp",
  "during_pregnancy_hyp",
  "hypertension_score",
  "risk_cat_hyp",
  "message_hyp",
  "nurse_task_hyp",
  "screening_type_hyp",
  "notes_hyp",
  "diagnosed_diabetes",
  "on_medication_diabetes",
  "told_you_have_diabetes",
  "excessive_thirst",
  "family_history_diabetes",
  "long_healing_wounds",
  "excessive_tiredness",
  "diabetes_score",
  "risk_cat_diabetes",
  "during_pregnancy_diabetes",
  "message_diabetes",
  "nurse_task_diabetes",
  "screening_type_diabetes",
  "notes_diabetes",
  "prostate_exam",
  "pap_smear",
  "cancer_score",
  "risk_cat_cancer",
  "nurse_task_cancer",
  "screening_type_cancer",
  "notes_cancer",
  "had_test",
  "test_result",
  "on_medication_hiv",
  "recent_test",
  "hiv_score",
  "risk_cat_hiv",
  "message_hiv",
  "nurse_task_hiv",
  "screening_type_hiv",
  "notes_hiv",
  "persistent_cough",
  "fever",
  "night_sweats",
  "weight_loss",
  "contact",
  "tb_score",
  "risk_cat_tb",
  "message_tb",
  "nurse_task_tb",
  "screening_type_tb",
  "notes_tb",
  "abdominal_pain",
  "discharge",
  "genital_sores",
  "urination_pain",
  "sti_score",
  "risk_cat_sti",
  "message_sti",
  "nurse_task_sti",
  "screening_type_sti",
  "notes_sti",
  "weight",
  "weightDate",
  "weightDescription",
  "height",
  "bmi",
  "bmiDate",
  "bmiRiskcat",
  "bmiNurseTask",
  "bmiScreen",
  "SBP",
  "DBP",
  "bpDate",
  "bpMedication",
  "bpLocation",
  "bpRiskcat",
  "bpNurseTask",
  "bpScreen",
  "bgRandom",
  "bgFasting",
  "bgDate",
  "bgHbaA1c",
  "hba1cDate",
  "bgMedication",
  "bgLocation",
  "bgNurseTask",
  "bgScreen",
  "glucoseRiskcat",
  "hba1cRiskcat",
  "cholesterol",
  "cholesterolDate",
  "cholesterolMedication",
  "cholesterolLocation",
  "prescriptionMeds",
  "cholesterolNurseTask",
  "cholesterolScreen",
  "cholesterolRiskcat",



];

const fieldMap = {
  reference_num: "referenceNum",
  name: "name",
  fullname: "fullName",
  id_number: "idNumber",
  cellphone: "cellphone",
  work_number: "workNumber",
  date_of_completion: "dateOfCompletion",
  campaign_code: "campaignCode",
  date_of_birth: "dateOfBirth",
  dob_string: "dobString",
  age_band: "ageBand",
  fields_completed: "fieldsCompleted",
  contact_consent: "contactConsent",
  pharmacy_consent: "pharmacyConsent",
  completed_by: "completedBy",
  on_medication: "onMedication",
  told_you_have_diabetes: "toldYouHaveDiabetes",
  excessive_urination: "excessiveUrination",
  excessive_thirst: "excessiveThirst",
  family_history: "familyHistory",
  excessive_tiredness: "excessiveTiredness",
  diabetes_score: "diabetesScore",
  risk_cat: "riskCat",
  during_pregnancy: "duringPregnancy",
  cancer_data: "cancerData",
  hiv_data: "hivData",
  tb_data: "tbData",
  sti_data: "stiData",
  diabetes_data: "diabetesData",
  cardio_data: "cardioData",
  hypertension_data: "hypertensionData",
  prostate_exam: "prostateExam",
  pap_smear: "papSmear",
  cancer_score: "cancerScore",
  stroke_or_heart_attack: "strokeOrHeartAttack",
  high_cholestrol: "highCholestrol",
  cardio_score: "cardiovascularScore",
  cardiovascular_score: "cardiovascularScore",
  told_you_have_hypertension: "toldYouHaveHyptertension",
  hypertension_score: "hypertensionScore",
  has_test: "hadTest",
  test_result: "testResult",
  recent_test: "recentTest",
  hiv_score: "hivScore",
  persistent_cough: "persistentCough",
  night_sweats: "nightSweats",
  weight_loss: "weightLoss",
  tb_score: "tbScore",
  abdominal_pain: "abdominalPain",
  genital_sores: "genitalSores",
  urination_pain: "urinationPain",
  sti_score: "stiScore",
  long_healing_wounds: "wounds",
};
export { helpers };
