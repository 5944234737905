const state = {
  numbersData: null,
};

const getters = {
  getNumbersData: (state) => {
    return state.numbersData;
  },
};

const actions = {
  saveNumbersData(context, payload) {
    context.commit("saveNumbersData", payload);
  },
  clearNumbers(context) {
    context.commit("clearNumbers");
  },
};

const mutations = {
  saveNumbersData(state, payload) {
    state.numbersData = payload;
  },
  clearNumbers(state) {
    state.numbersData = null
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
