import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@/styles/default.scss';
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { ApiService } from './services/api.service'
import { helpers } from "./utils/helpers.js"


// internal icons
import {
    faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload
} from "@fortawesome/free-solid-svg-icons";

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload);
Vue.component('vue-fontawesome', FontAwesomeIcon);


library.add(fas);
Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(Buefy, {
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas',
});

Vue.mixin({
    methods: helpers // register helper functions as mixin that will be avail to all components through this.<method>
})

ApiService.init('/api');

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
