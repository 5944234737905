<template>
  <div>
    <!-- Your template goes here -->
    <div class="columns">
      <div class="column">
        <b-field
          label="Member Number"
          message="Please copy this accurately from your membership card"
        >
          <b-input
            v-model="personal_details.member_number"
            size="is-small"
            required
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Dependent Code" :type="String(successfulDependentCode)">
          <b-tooltip
            label="This field is for the family member who is completing the form. The Main/Principal member's dependent code is always 00. Each dependent will have their own two digit code for example 01, 02, 03 etc."
            type="is-light"
            position="is-bottom"
            :triggers="['hover', 'focus']"
            multilined
            style="width: 100%"
          >
            <b-input
              v-model.trim="personal_details.dependent_code"
              size="is-small"
              placeholder="0"
              required
            ></b-input>
          </b-tooltip>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BonitasPersonalComponent",
  data() {
    return {
      personal_details: {
        member_number: "",
        dependent_code: "",
      },
    };
  },
  props: {
    default_bonitas_personal_details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Your component methods go here
  },
  computed: {
    // Your computed properties go here
    successfulDependentCode() {
      //check if dependent code is two consecutive digits from 00 to 20
      // use a regex match
      const depCode = this.personal_details.dependent_code;
      if (depCode.length == 0) {
        return "";
      } else {
        const regex = new RegExp("^[0-2][0-9]$");
        if (regex.test(depCode)) {
          return "is-success";
        } else {
          return "is-danger";
        }
      }
    },
  },
  watch: {
    personal_details: function () {
      // console.log(this.personal_details)
      this.$emit("valueChanged", this.personal_details);
    },
  },
  created() {
    this.personal_details = this.default_bonitas_personal_details;
  },
  mounted() {
    // Your mounted lifecycle hook goes here
  },
};
</script>

<style scoped>
/* Your component styles go here */
</style>