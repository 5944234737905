<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 150px;
        padding-left: 20px;
      "
    >
      <b-field>
        <p class="title is-5">Mental Health</p>
      </b-field>
      <b-field
        ><p id="info" @click="showMentalHealthInfo()">
          {{ mentalHealthInfoVisible ? "hide" : "more information" }}
        </p></b-field
      >
      <b-field
        style="color: $primary; width: device-width"
        v-if="mentalHealthInfoVisible"
        ><p style="width: device-width">{{ mentalHealthInfoText }}</p></b-field
      >
      <the-nav-buttons
        :backEnabled="true"
        :nextEnabled="true"
        source="MentalHealth"
        @clearFields="clear()"
      ></the-nav-buttons>

      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to complete this section of this assessment
      </b-message>
      <form
        @submit.prevent="submitMentalHealthData()"
        style="padding-top: 15px"
        v-if="visible"
      >
        <div class="columns is-desktop">
          <div class="column">
            <p class="title is-6">
              During the past week, how many days have you been feeling this
              way:
            </p>
          </div>
          <div class="column is-one-quarter-desktop is-one-half-mobile">
            <p class="title is-6" style="text-align: center">
              0 days &nbsp; 1-2 days &nbsp; 3-4 days &nbsp; 5-7 days
            </p>
          </div>
        </div>

        <question
          v-for="(question, index) in questions"
          :key="index"
          :index="index + 1"
          :question="question.text"
          :radioName="question.dataProperty"
          :dataProperty="question.dataProperty"
          @answered="updateObject"
          :value="mentalHealthData[question.dataProperty]"
          type="radio"
          :radioOptions="[1, 2, 3, 4]"
          :radioLabels="{ 1: '', 2: '', 3: '', 4: '' }"
        >
        </question>
        <question
          v-if="mentalHealthData.mental_health_score > 15"
          :index="17"
          question="When do you remember first feeling any of these emotions?"
          type="date"
          :value="mentalHealthData.date_feelings_started"
          @answered="updateObject"
          dataProperty="date_feelings_started"
        ></question>
        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit</b-button
          >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
import question from "../ui/Question.vue";
export default {
  components: {
    NavBar,
    question,
    TheNavButtons,
  },
  data() {
    return {
      mentalHealthInfo: "more information",
      mentalHealthInfoText:
        "Mental health refers to psychological, emotional, behavioural, and social well-being. When symptoms such as depression or anxiety are present, psychological therapy can be effective. Psychological therapy involves identifying and changing negative thought patterns, developing healthy coping skills, and improving overall mental health. The benefits of therapy can include improved mood, reduced anxiety, increased self-esteem, and better relationships. It is important to seek help from a qualified mental health professional if you are experiencing symptoms of depression or anxiety.",
      mentalHealthInfoVisible: true,
      visible: true,
      age: null,
      gender: null,
      isLoading: false,
      continue: false,

      mentalHealthData: {
        referenceNum: "",
        not_going_well: null,
        trouble_sleeping: null,
        overthinking: null,
        be_alone: null,
        tired: null,
        unusual_self: null,
        stressed: null,
        heavyness: null,
        not_talking: null,
        heart_pain: null,
        cried: null,
        irritable: null,
        sad: null,
        appetite: null,
        interest: null,
        sorrow: null,
        date_feelings_started: null,
        mental_health_score: null,
        risk_cat: null,
        message: null,
      },
      questions: [
        {
          text: "Things were not going well in my life",
          dataProperty: "not_going_well",
        },
        {
          text: "I had difficulties sleeping",
          dataProperty: "trouble_sleeping",
        },
        {
          text: "I was thinking too much",
          dataProperty: "overthinking",
        },
        {
          text: "I did not want to be around people",
          dataProperty: "be_alone",
        },
        {
          text: "I was feeling tired or without energy",
          dataProperty: "tired",
        },
        {
          text: "I was not my usual self",
          dataProperty: "unusual_self",
        },
        {
          text: "I was carrying a lot of weight on my shoulders",
          dataProperty: "heavyness",
        },
        {
          text: "I had stress",
          dataProperty: "stressed",
        },
        {
          text: "I did not feel like talking",
          dataProperty: "not_talking",
        },
        {
          text: "I had pain in my heart",
          dataProperty: "heart_pain",
        },
        {
          text: "I cried",
          dataProperty: "cried",
        },
        {
          text: "I was irritated easily",
          dataProperty: "irritable",
        },
        {
          text: "I was sad",
          dataProperty: "sad",
        },
        {
          text: "I had no appetite or I ate more than usual",
          dataProperty: "appetite",
        },
        {
          text: "I was not interested in the things I usually like doing",
          dataProperty: "interest",
        },
        {
          text: "I had deep sorrow",
          dataProperty: "sorrow",
        },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapGetters(["getAge", "getGender", "getRefNum", "getCardioData"]),
    updateObject(answer, dataProperty) {
      this.mentalHealthData[dataProperty] = answer;
      this.mentalHealthData.mental_health_score = this.determineScore();
    },

    showMentalHealthInfo() {
      this.mentalHealthInfoVisible = !this.mentalHealthInfoVisible;
      if (this.mentalHealthInfoVisible) {
        this.mentalHealthInfo = "hide";
      } else {
        this.mentalHealthInfo = "more information";
      }
    },

    determineScore() {
      var depressionScore = 0;
      for (const key of Object.keys(this.mentalHealthData)) {
        //if value of key is of type int, add to depression score
        if (
          typeof this.mentalHealthData[key] === "number" &&
          key != "mental_health_score"
        ) {
          depressionScore += this.mentalHealthData[key] - 1; //subtract one since the first option is 0 days which shouldn't contribute to the score
        }
      }
      return depressionScore;
    },
    determineRiskCategory() {
      if (this.mentalHealthData.mental_health_score <= 15) {
        return "low risk";
      } else {
        return "high risk";
      }
    },

    async getActionMessage(score, cat, category) {
      let resp = QueryService.get_ActionMessage(category, score, cat)
        .then((res) => {
          if (res.data.length == 0) {
            console.log("Action message empty.");
            return "empty";
          } else {
            return res.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
      return resp;
    },

    async submitMentalHealthData() {
      var gender = this.gender;

      this.mentalHealthData.mental_health_score = this.determineScore();
      this.mentalHealthData.risk_cat = this.determineRiskCategory();
      var resp = await this.getActionMessage(
        0,
        this.mentalHealthData.risk_cat,
        "mental health"
      );
      if (resp) {
        if ((gender == "male") | (gender == null)) {
          this.mentalHealthData.message = resp.message_male;
        } else {
          this.mentalHealthData.message = resp.message_female;
        }
        this.mentalHealthData.screening_type = resp.screening_type;
        this.mentalHealthData.nurse_task = resp.nurse_task;
        this.$store.dispatch("saveMentalHealthData", this.mentalHealthData);
        this.saveMentalHealthToDB(this.mentalHealthData);
      }
    },
    saveMentalHealthToDB(obj) {
      this.isLoading = true;
      QueryService.update_withMentalHealth(obj)
        .then((response) => {
          this.successToast("saved mental health data", 2000);
          this.isLoading = false;
          this.continue = true;
          this.$store.dispatch("setMentalHealthFinished", true);
        })
        .catch((error) => {
          console.log(error);
          this.errorToast("error saving mental health data", 4000);
          this.isLoading = false;
        });
    },
    clear() {
      this.mentalHealthData = {
        referenceNum: "",
        not_going_well: null,
        trouble_sleeping: null,
        overthinking: null,
        be_alone: null,
        tired: null,
        unusual_self: null,
        stressed: null,
        heavyness: null,
        not_talking: null,
        heart_pain: null,
        cried: null,
        irritable: null,
        sad: null,
        appetite: null,
        interest: null,
        sorrow: null,
        mental_health_score: null,
        risk_cat: null,
        message: null,
      };
      this.mentalHealthData.referenceNum = this.$store.getters.getRefNum;
    },
  },
  watch: {
    continue() {
      if (this.continue == true) {
        this.continue = false;
        this.$router.push({ name: "FemaleHealth" });
      }
    },
  },
  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.mentalHealth;
    }

    this.age = this.$store.getters.getAge; //get age when component created
    this.gender = this.$store.getters.getGender;
    const mentalHealthObject = this.$store.getters.getMentalHealthData;

    if (mentalHealthObject) {
      //if not null
      this.mentalHealthData = mentalHealthObject;
    }

    this.mentalHealthData.referenceNum = this.$store.getters.getRefNum;
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.dispatch("saveMentalHealthData", this.mentalHealthData); //save details upon leaving page
    next();
  },
};
</script>

<style scoped>
.customQuestion {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
