import VueCookies from  'vue-cookies';

//const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const USERNAME_KEY = 'username'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/

const LocalStorage={
    save(name, object) {
        VueCookies.set(name, object)
    },
    get(name){
        return VueCookies.get(name)
    },
    remove(name){
        VueCookies.remove(name)
    }
}

const TokenService = {
    getToken(name) {
        return VueCookies.get(name)
    },
    saveToken(name, accessToken, expiresInMinutes) {
        VueCookies.set(name, accessToken, expiresInMinutes)
    },
    
    getUser(){
        return VueCookies.get(USERNAME_KEY)
    },

    removeUser(){
        VueCookies.remove(USERNAME_KEY)
    },

    saveUser(username){
        VueCookies.set(USERNAME_KEY, username, '12h')
    },

    removeToken(name) {
        VueCookies.remove(name)
    },

    getRefreshToken(name) {
        return VueCookies.get(name)
    },

    saveRefreshToken(refreshToken) {
        VueCookies.set(REFRESH_TOKEN_KEY, refreshToken, '1d')
    },

    removeRefreshToken(name) {
        VueCookies.remove(name)
    }

}

export { TokenService, LocalStorage }