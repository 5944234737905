

// import {QueryService} from '../../services/query.service'
// import { ToastProgrammatic as toast } from 'buefy'
const state =  { //store the progress of the user to see if they have filled in the necessary fields
    userDetails:null, //object to store full userDetails object
    formValid:false,
    formComplete:false,
    saveCompleted:false
}

const getters = {
    getName:(state)=>{
        if(state.userDetails != null){
            return state.userDetails.name;
        }else{
            return "";
        }
    },
    getRefNum:(state)=>{
        if(state.userDetails != null){
            return state.userDetails.referenceNum;
        }else{
            return "";
        }
    },
    getUserObject:(state)=>{
        if(state.userDetails != null){
            return state.userDetails;
        }else{
            return null;
        }
    },
    getFormCompletion:(state)=>{
        return state.formComplete;
    },
    getFormValidity:(state)=>{
        return state.formValid;
    },
    getAge:(state)=>{
        if(state.userDetails != null){
            return state.userDetails.age;
        }else{
            return null;
        }
    },
    getGender:(state)=>{
        if(state.userDetails != null){
            return state.userDetails.gender;
        }else{
            return null;
        }
    },
    getSaveCompletion:(state)=>{
        return state.saveCompleted;
    }

}
const mutations = {
    setUserDetails(state, details){
        state.userDetails = details;
        state.saveCompleted=true;
    },
    setRefNum(state, payload){
        state.userDetails.referenceNum = payload;
    },
    clearUserDetails(state){
        state.userDetails=null;
    },
    setFormAsComplete(state, value){
        state.formComplete=value;
    },
    setFormValidity(state, value){
        state.formValid=value;
    },
    setCardioData(state, payload){
        state.userDetails.cardioData=payload;
    },
    setHypertensionData(state, payload){
        state.userDetails.hypertensionData=payload;
    },
    setCancerData(state, payload){
        state.userDetails.cancerData=payload;
    },
    setHIVData(state, payload){
        state.userDetails.hivData=payload;
    },
    setTBData(state, payload){
        state.userDetails.tbData=payload;
    },
    setSTIData(state, payload){
        state.userDetails.stiData=payload;
    },
    clearAllStore(){
    
    
    }

  

}
const actions = {
    setUserDetails(context, details){
        context.commit('setUserDetails', details);
    },
    setRefNum(context, payload){
        context.commit('setRefNum',payload);
    },
    clearUserDetails(context){
        context.commit('clearUserDetails');
    },
    setFormCompletion(context, value){
        context.commit('setFormAsComplete', value);
    },
    setFormValidity(context, value){
        context.commit('setFormValidity', value);
    },
    setCardioData(context, payload){
        context.commit('setCardioData',payload);
    },
    setHypertensionData(context, payload){
        context.commit('setHypertensionData',payload);
    },
    setCancerData(context, payload){
        context.commit('setCancerData',payload);
    },
    setHIVData(context, payload){
        context.commit('setHIVData',payload);
    },
    setTBData(context, payload){
        context.commit('setTBData',payload);
    },
    setSTIData(context, payload){
        context.commit('setSTIData',payload);
    },

    clearAllStore(context){
        context.commit('clearAllStore')
    },
   


    saveUserToDB(context){
        context.commit('saveUserToDB');

    }
    
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}