

const state = {
    femaleHealthData: null
}

const getters = {
    getFemaleHealthData: (state) => {
        return state.femaleHealthData;
    }
}

const actions = {
    saveFemaleHealthData(context, payload) {
        context.commit('saveFemaleHealthData', payload);
    },
    clearFemaleHealth(context){
        context.commit('clearFemaleHealth')
    }


}

const mutations = {
    saveFemaleHealthData(state, payload) {
        state.femaleHealthData = payload;
    },
    clearFemaleHealth(state){
        state.femaleHealthData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}