<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 20px; padding-bottom: 150px; padding-left:20px;"
    >
      <b-field grouped>
        <p class="title is-5">{{ label }}</p>
      </b-field>
      <the-nav-buttons :backEnabled="true" :nextEnabled="false" @clearFields="clear()" source="Adhoc"></the-nav-buttons>
      <b-message type="is-primary" aria-close-label="Close message" v-if="!visible">
        You do not need to complete this section of this assessment. This is the end of the
        assessment.
      </b-message>
      <b-button v-if="!visible" type="is-primary" outlined native-type="submit" class="submitButton" @click="goToReport()">finish</b-button>
      <form @submit.prevent="submit()" style="padding-top:15px" v-if="visible && questions.length > 0">
        <question
          v-for="(q, index) in questions"
          :key="q.questionId"
          :index="index + 1"
          :radioName="q.questionId"
          :question="q.question"
          :type="q.questionType"
          :dropdownOptions="q.dropdownOptions"
          :dataProperty="q.questionId"
          @answered="getAnswer"
        ></question>
        <b-field position="is-right">
          <b-button type="is-primary" outlined native-type="submit" class="submitButton">submit</b-button>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { QueryService } from "../../services/query.service";
import Question from "../ui/Question.vue";
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: { Question, NavBar, TheNavButtons },
  data() {
    return {
      label: "Other",
      visible: true,
      isLoading: false,
      campaign: null,
      questions: [],
      answers: {},
      refNum: "",
    };
  },
  methods: {
    clear() {
      this.questions = [];
      this.loadQuestions(this.campaign.questionIds);
    },
    async loadQuestions(questionIds) {
      this.isLoading = true;
      try {
        let res = await QueryService.get_Questions(questionIds);
        for (var i = 0; i < res.data.questions.length; i++) {
          this.questions.push(JSON.parse(res.data.questions[i]));
        }
        this.$store.dispatch("saveQuestions", this.questions);

        this.isLoading = false;
      } catch (error) {
        this.errorToast(error.response.data.message, 4000);
        this.isLoading = false;
      }
    },
    getAnswer(answer, qId, question) {
      this.answers[qId] = {
        qId: qId,
        userReference: this.refNum,
        answer: answer,
        question: question,
        campaign: this.campaign.campaignNumber,
        company: this.campaign.customer,
      };
    },
    async submit() {
      this.isLoading = true;
      try {
        let res = await QueryService.update_withAdhoc(this.answers, this.$store.getters.getRefNum);
        this.successToast(res.data.message, 2000);
        this.isLoading = false;
        this.$store.dispatch("setAdhocFinished", true);
        this.$router.push({ name: "SummaryReport" });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.errorToast(error.responses.data.message, 4000);
      }
    },
    goToReport() {
      this.$router.push({ name: "SummaryReport" });
    },
  },
  created() {
    this.refNum = this.$store.getters.getRefNum;
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.adhoc;
    }
    var camp = this.$store.getters.getCampaign;
    if (camp != null) {
      // var l = this.$store.getters.getLabel;
      // console.log("L: ",l)
      // if (l != "") {
      //   this.label = l;
      // }
      this.campaign = camp;
      var qs = this.$store.getters.getQuestions;
      if (qs == null) {
        this.loadQuestions(this.campaign.questionIds);
      } else {
        this.questions = qs;
      }
    }
  },
};
</script>
