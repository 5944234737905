<template>
  <div>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 120px; padding-bottom: 150px; padding-left:0px;"
    >
      <b-field>
        <p class="title is-5">Create Admin User</p>
      </b-field>
      <form @submit.prevent="register()" style="padding-top:15px;">
        <div class="columns">
          <div class="column is-mobile is-one-quarter">
            <b-field label="First name" label-position="on-border">
              <b-input v-model.trim="user.first_name" required></b-input>
            </b-field>
          </div>
          <div class="column is-mobile is-one-quarter">
            <b-field label="Last name" label-position="on-border">
              <b-input v-model.trim="user.last_name" required></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-mobile is-one-quarter">
            <b-field label="Email" label-position="on-border">
              <b-input v-model.trim="user.email" required></b-input>
            </b-field>
          </div>
          <div class="column is-mobile is-one-quarter">
            <b-field label="Password" label-position="on-border">
              <b-input
                type="password"
                v-model.trim="user.password"
                required
                password-reveal
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-mobile is-one-quarter">
            <b-field label="Organisation" label-position="on-border">
              <b-input
                v-model.trim="user.organisation"
                placeholder="optional"
              ></b-input>
            </b-field>
          </div>
        </div>

        <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>

        <b-field grouped>
          <b-button native-type="submit" outlined type="is-primary"
            >register user</b-button
          >
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { smsService } from "../../services/sms.service.js";
import { UserService } from "../../services/user.service";
export default {
  data() {
    return {
      isLoading: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        organisation: "",
      },
    };
  },
  methods: {
    async register() {
      this.isLoading = true;
      let res = await UserService.register(this.user);
      this.isLoading = false;
      if (res.status == 200) {
        this.successToast("User registered successfully");
        this.clear();
      } else {
        if (res.data.error == "email_exists") {
          this.errorToast("User already exists");
        } else {
          this.errorToast("Could not register user");
        }
      }
      console.log(res);
    },
    async getSms() {
      // await smsService.getAccessToken();
      const messages = [
        { Content: "SMS test shalome", Destination: "0728700698" },
      ];
      let res = await smsService.sendSMS(messages);
      console.log(res);
    },
    clear() {
      this.user = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        organisation: "",
      };
    },
  },
  computed: {},
};
</script>

<style scoped></style>
