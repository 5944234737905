

const state =  {
    allCancerData:null,
} 


const getters = {
    getCancerData:(state)=>{
        return state.allCancerData;
    }

}

const actions = {
    saveCancerData(context, payload){
        context.commit('saveCancerData', payload)
    },
    clearCancer(context){
        context.commit('clearCancer')
    }

}

const mutations = {
    saveCancerData(state, payload){
        state.allCancerData = payload;
    },
    clearCancer(state){
        state.allCancerData = null;
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}