<template>
  <div
    style="padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left:0px;"
  >
    <!-- <div
      class="has-text-left container"  > -->

    <div class="columns is-mobile">
      <div class="column" style="font-size:12px;">
        <p>Question Id:</p>
        <p>{{ question.questionId }}</p>
      </div>

      <div class="column">
        <b-field label="Question type" label-position="on-border">
          <b-select v-model="question.questionType" size="is-small" required>
            <option v-for="option in questionTypes" :key="option">{{
              option
            }}</option>
          </b-select>
        </b-field>
      </div>

      <div
        class="column is-one-fifth"
        v-if="question.questionType == 'dropdown'"
      >
        <b-field
          label="Dropdown options"
          label-position="on-border"
          message="Press enter to add item to list"
        >
          <b-taginput
            size="is-small"
            ellipsis
            aria-close-label="Delete this tag"
            v-model="question.dropdownOptions"
          >
          </b-taginput>
        </b-field>
      </div>

      <div class="column is-three-fifths">
        <b-field label="Question" label-position="on-border">
          <b-input
            type="textarea"
            size="is-small"
            v-model="question.question"
            required
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-button
          rounded
          outlined
          type="is-primary"
          icon-left="trash"
          size="is-small"
          @click="deleteMe()"
        ></b-button>
      </div>
    </div>

    <hr />
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      questionTypes: ["yes/no", "dropdown", "date"],
      question: {
        questionId:"",
        questionType: "",
        dropdownOptions: [],
        question: "",
      },
    };
  },
  props: {
    qId: String,
    type: String,
    dropdownOptions:Array,
    questionText: String,
    save: Boolean,
  },
  methods: {
    deleteMe() {
      this.$emit("deleteQuestion", this.question.questionId);
    },
  },
  watch: {
    save: function(newVal) {
      if (newVal == true) {
        this.$emit("saveQuestion", this.question);
      }
    },
  },

  created(){
      this.question.questionId = this.qId;
      this.question.questionType = this.type;
      this.question.dropdownOptions = this.dropdownOptions;
      this.question.question = this.questionText;
  }
};
</script>
