<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container has-text-left" style="padding: 10px 80px 10px 20px">
      <!--padding: top, right, bottom, left-->
      <p>
        This assessment is a tool we use to assess your health risk and to help
        us refer you for the appropriate screening. This tool is only a screen
        to assess you health and should not be used for diagnostic purposes or
        to replace seeking medical care. <br /><br />
        There are x questions but depending on your answer, you may not need to
        answer all of them. This assessment takes about 5 minutes to complete.
        You have the option to refuse to participate and that will not affect
        our services to you in any way. We will use the information you give us
        to determine if you require a call from a CareWorks nurse to discuss
        your results and a voucher to your nearest pharmacy for a physical
        screen. Your answers will be treated with the stricted of
        confidentiality and will not be published in any form. <br /><br />
        Please see your healthcare provider if you are unsure about your risk.
      </p>
    </div>
    <div class="has-text-left container" style="padding: 30px 80px 10px 20px">
      <b-field>
        <p>Do you consent to having the pharmacy send us your results?</p>
        <b-button
          :type="{ 'is-focused': isActive.yes1 }"
          @click="capturePharmacyConsent('yes1')"
          style="margin-left: 148px; margin-right: 10px"
          >yes</b-button
        >
        <b-button
          :type="{ 'is-focused': isActive.no1 }"
          @click="capturePharmacyConsent('no1')"
          style="margin-left: 10px; margin-right: 10px"
          >no</b-button
        >
      </b-field>
      <b-field>
        <p>
          Do you consent to being contacted by our contact centre for health
          education?
        </p>
        <b-button
          :type="{ 'is-focused': isActive.yes2 }"
          @click="captureContactConsent('yes2')"
          style="margin-left: 20px; margin-right: 10px"
          >yes</b-button
        >
        <b-button
          :type="{ 'is-focused': isActive.no2 }"
          @click="captureContactConsent('no2')"
          style="margin-left: 10px; margin-right: 10px"
          >no</b-button
        >
      </b-field>
    </div>
    <b-button style="margin-top: 50px" @click="validateCapture">next</b-button>
  </div>
</template>
<script>
import NavBar from './ui/NavBar.vue';
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      isActive: { yes1: false, yes2: false, no1: false, no2: false }, //button focus states
      pharmacyConsent: null,
      contactConsent: null,
    };
  },
  methods: {
    capturePharmacyConsent(source) {
      this.toggleFocus(source);
      if (source == "yes1") {
        this.pharmacyConsent = true;
      } else {
        this.pharmacyConsent = false;
      }
    },
    captureContactConsent(source) {
      this.toggleFocus(source);
      if (source == "yes2") {
        this.contactConsent = true;
      } else {
        this.contactConsent = false;
      }
    },

    validateCapture() {
      if (this.pharmacyConsent != null || this.contactConsent != null) {
        console.log(this.$store.getters.completedIntro);
        this.$router.push({ name: 'Personal' });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Please select yes or no for each question before proceeding.",
          type: "is-danger",
        });
      }
    },

    toggleFocus(source) {
      if (source == "yes2") {
        if (this.isActive.yes2) {
          this.isActive.yes2 = false;
        } else {
          this.isActive.yes2 = true;
          this.isActive.no2 = false;
        }
      }
      if (source == "no2") {
        if (this.isActive.no2) {
          this.isActive.no2 = false;
        } else {
          this.isActive.no2 = true;
          this.isActive.yes2 = false;
        }
      }
      if (source == "yes1") {
        if (this.isActive.yes1) {
          this.isActive.yes1 = false;
        } else {
          this.isActive.yes1 = true;
          this.isActive.no1 = false;
        }
      }
      if (source == "no1") {
        if (this.isActive.no1) {
          this.isActive.no1 = false;
        } else {
          this.isActive.no1 = true;
          this.isActive.yes1 = false;
        }
      }
    },
  },
};
</script>
