 <template>
  <b-navbar id="nav" centered spaced>
    <template #brand>
      <b-navbar-item>
        <the-logo />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ name: 'Personal' }">
        Personal
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Cardio' }">
        <b-tag v-if="cardio" type="is-success is-light" size="is-medium">
          Cardio</b-tag
        >
        <p v-if="!cardio">Cardio</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Hypertension' }">
        <b-tag v-if="hypertension" type="is-success is-light" size="is-medium">
          Hypertension</b-tag
        >
        <p v-if="!hypertension">Hypertension</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Diabetes' }">
        <b-tag v-if="diabetes" type="is-success is-light" size="is-medium">
          Diabetes</b-tag
        >
        <p v-if="!diabetes">Diabetes</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Cancer' }">
        <b-tag v-if="cancer" type="is-success is-light" size="is-medium">
          Cancer Prevention</b-tag
        >
        <p v-if="!cancer">Cancer Prevention</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'HIV' }">
        <b-tag v-if="hiv" type="is-success is-light" size="is-medium">
          HIV</b-tag
        >
        <p v-if="!hiv">HIV</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'MentalHealth' }">
        <b-tag v-if="mentalHealth" type="is-success is-light" size="is-medium">
          Mental Health</b-tag
        >
        <p v-if="!mentalHealth">Mental Health</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'FemaleHealth' }">
        <b-tag v-if="femaleHealth" type="is-success is-light" size="is-medium">
          Reproductive Health</b-tag
        >
        <p v-if="!femaleHealth">Reproductive Health</p>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Numbers' }">
        <b-tag v-if="numbers" type="is-success is-light" size="is-medium">
          Health Check</b-tag
        >
        <p v-if="!numbers">Health Check</p>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <b-field grouped position="is-centered">
          <b-field>
            <b-button
              class=""
              @click="goToReport"
              outlined
              type="is-secondary"
              size="is-default"
              rounded
              >Report</b-button
            >
          </b-field>
          <b-field>
            <b-button
              class=""
              @click="exitAssessment"
              outlined
              type="is-primary"
              size="is-default"
              rounded
              >Exit</b-button
            >
          </b-field>
        </b-field>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
   
<script>
import { LocalStorage } from "../../services/storage.service";
import TheLogo from "./TheLogo.vue";
export default {
  components: {
    TheLogo,
  },
  data() {
    return {
      loggedIn: true,
      label: "Other",
      cardio: false,
      hypertension: false,
      numbers: false,
      diabetes: false,
      hiv: false,
      cancer: false,
      adhoc: false,
      mentalHealth: false,
      femaleHealth: false,
    };
  },
  methods: {
    exitAssessment() {
      if (this.$route.name != "Personal") {
        this.$buefy.dialog.confirm({
          title: "Exiting",
          message:
            "Are you sure you want to exit the Health Risk Assessment? All of your progress will be lost.",
          confirmText: "Exit",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            LocalStorage.remove("userDetails");
            this.$router.push({name: "Home"});
          },
        });
      } else {
        this.$router.push({name: "Home"});
      }
    },
    goToReport() {
      this.$router.push({ name: "SummaryReport" });
    },
    assessmentSectionStatus() {
      this.cardio = this.$store.getters.cardioFinished;
      this.hypertension = this.$store.getters.hypertensionFinished;
      this.numbers = this.$store.getters.numbersFinished;
      this.diabetes = this.$store.getters.diabetesFinished;
      this.hiv = this.$store.getters.hivFinished;
      this.adhoc = this.$store.getters.adhocFinished;
      this.cancer = this.$store.getters.cancerFinished;
      this.mentalHealth = this.$store.getters.mentalHealthFinished;
      this.femaleHealth = this.$store.getters.femaleHealthFinished;
    },
  },

  created() {
    this.assessmentSectionStatus();

    this.clientName = this.$route.params.client || "default";
  },
};
</script>

<style lang="scss" scoped>
.exit {
  position: relative;
  top: 60px;
  padding: 10px 10px 10px 10px;
  margin-right: 20px;
}
.report {
  position: relative;
  top: 60px;
  padding: 10px 10px 10px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.centered {
  text-align: center;
  /* margin: auto;
   width: 120%; */
  /* padding: 50px 10px 10px 10px;  */
}

.navbar-item img {
  max-height: 5rem !important;
}
</style>
