

const state =  { //store the progress of the user to see if they have filled in the necessary fields
    cardioFinished:false,
    hypertensionFinished:false,
    diabetesFinished:false,
    numbersFinished:false,
    cancerFinished:false,
    hivFinished:false,
    mentalHealthFinished:false,
    adhocFinished:false,
    femaleHealthFinished:false,
}

const getters = {
    cardioFinished(state){
        return state.cardioFinished;
    },
    hypertensionFinished(state){
        return state.hypertensionFinished;
    },
    diabetesFinished(state){
        return state.diabetesFinished;
    },
    numbersFinished(state){
        return state.numbersFinished;
    },
    cancerFinished(state){
        return state.cancerFinished;
    },
    hivFinished(state){
        return state.hivFinished;
    },
    mentalHealthFinished(state){
        return state.mentalHealthFinished;
    },
    adhocFinished(state){
        return state.adhocFinished;
    },
    femaleHealthFinished(state){
        return state.femaleHealthFinished;
    }

}

const actions = {
    setCardioFinished(context, payload){
        context.commit('setCardioFinished', payload)
    },
    setHypertensionFinished(context, payload){
        context.commit('setHypertensionFinished', payload)
    },
    setDiabetesFinished(context, payload){
        context.commit('setDiabetesFinished', payload)
    },
    setNumbersFinished(context, payload){
        context.commit('setNumbersFinished', payload)
    },

    setCancerFinished(context, payload){
        context.commit('setCancerFinished', payload)
    },
    setHivFinished(context, payload){
        context.commit('setHivFinished', payload)
    },
    setMentalHealthFinished(context, payload){
        context.commit('setMentalHealthFinished', payload)
    },
    setAdhocFinished(context, payload){
        context.commit('setAdhocFinished', payload)
    },
    setFinishedFalse(context){
        context.commit('setFinishedFalse')
    },
    setFemaleHealthFinished(context, payload){
        context.commit('setFemaleHealthFinished', payload)
    }

}

const mutations = {

    setCardioFinished(state, payload){
        state.cardioFinished = payload
    },
    setHypertensionFinished(state, payload){
        state.hypertensionFinished = payload
    },
    setDiabetesFinished(state, payload){
        state.diabetesFinished = payload
    },
    setNumbersFinished(state, payload){
        state.numbersFinished = payload
    },

    setCancerFinished(state, payload){
        state.cancerFinished = payload
    },
    setHivFinished(state, payload){
        state.hivFinished = payload
    },
    setMentalHealthFinished(state, payload){
        state.mentalHealthFinished = payload;
    },
    setAdhocFinished(state, payload){
        state.adhocFinished = payload
    },
    setFemaleHealthFinished(state, payload){
        state.femaleHealthFinished = payload
    },
    setFinishedFalse(state){
        state.cardioFinished=false;
        state.hypertensionFinished=false;
        state.diabetesFinished=false;
        state.numbersFinished=false;
        state.cancerFinished=false;
        state.hivFinished=false;
        state.adhocFinished=false;
        state.mentalHealthFinished=false;
        state.femaleHealthFinished=false;
    }

}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}