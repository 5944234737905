<template>
  <div>
    <hr />
    <b-field grouped>
      <p style="padding-right:5px;">{{ index + 1 }}.</p>
      <b-button v-if="editedVal" @click="update()" type="is-secondary" size="is-small" outlined rounded style="margin-right:5px;"
        >{{buttonText}}</b-button
      >
      <b-field label="Campaign" label-position="on-border">
        <b-input type="text" v-model.trim="campaignCodeVal" size="is-small"></b-input>
      </b-field>
      <b-field label="Risk Cat" label-position="on-border">
        <b-input type="text" v-model.trim="riskCatVal" size="is-small"></b-input>
      </b-field>

      <b-field label="Score" label-position="on-border">
        <b-input type="text" v-model.trim="scoreValue" size="is-small"></b-input>
      </b-field>
      <b-field label="Screen Type" label-position="on-border">
        <b-input
          type="text"
          v-model.trim="screeningTypeVal"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field label="Nurse Task" label-position="on-border" expanded>
        <b-input
          type="textarea"
          v-model.trim="nurseTaskVal"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field label="Message (male)" label-position="on-border" expanded>
        <b-input
          type="textarea"
          v-model.trim="messageMaleVal"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field label="Message (female)" label-position="on-border" expanded>
        <b-input
          type="textarea"
          v-model.trim="messageFemaleVal"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field label="SMS Message" label-position="on-border" expanded>
        <b-input
          type="textarea"
          v-model.trim="smsMessageVal"
          size="is-small"
        ></b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    edited: Boolean,
    score: String,
    riskCat: String,
    nurseTask: String,
    messageMale: String,
    messageFemale: String,
    smsMessage: String,
    screeningType: String,
    campaignCode: String,
    buttonText: String
  },

  data() {
    return {
      editedVal:this.edited,
      scoreValue: this.score,
      riskCatVal: this.riskCat,
      nurseTaskVal: this.nurseTask,
      messageMaleVal: this.messageMale,
      messageFemaleVal: this.messageFemale,
      smsMessageVal: this.smsMessage,
      screeningTypeVal: this.screeningType,
      campaignCodeVal: this.campaignCode,
    };
  },

  methods: {
    update() {
      const actionMessage = {
        score: this.scoreValue,
        risk_cat: this.riskCatVal.toLowerCase(),
        nurse_task: this.nurseTaskVal,
        message_male: this.messageMaleVal,
        message_female: this.messageFemaleVal,
        sms_message: this.smsMessageVal,
        screening_type: this.screeningTypeVal,
        campaign_code: this.campaignCodeVal,
      };
      this.$emit("changed", actionMessage);
    },
  },
  watch: {
    scoreValue: function() {
      this.editedVal=true;
    },
    riskCatVal: function() {
      this.editedVal=true;
    },
    nurseTaskVal: function() {
      this.editedVal=true;
    },
    messageMaleVal: function() {
      this.editedVal=true;
    },
    messageFemaleVal: function() {
      this.editedVal=true;
    },
    smsMessageVal: function() {
      this.editedVal=true;
    },
    screeningTypeVal: function() {
      this.editedVal=true;
    },
    campaignCodeVal: function() {
      this.editedVal=true;
    },
  },
};
</script>
