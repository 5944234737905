<template>
  <div>
    <div
      class="has-text-left container"
      style="padding-top: 30px; padding-right: 0px; padding-bottom: 150px; padding-left:0px;"
    >
      <b-field grouped>
        <p class="title is-5">Campaign Editor</p>
      </b-field>

      <div style="padding-bottom:15px;">
        <b-field grouped group-multiline>
          <b-field>
            <b-button
              rounded
              size="is-small"
              icon-left="plus"
              @click="createNewCampaign()"
              >New Campaign</b-button
            >
          </b-field>
          <b-field>
            <b-button
              rounded
              size="is-small"
              icon-left="pen"
              @click="editExistingCampaign()"
              >Edit Campaign</b-button
            >
          </b-field>
        </b-field>
      </div>

      <div v-if="editCampaign" style="padding-top:15px;">
        <p v-if="existingCampaigns.length == 0">
          There are no existing campaigns. Please create a new one.
        </p>
        <b-field v-if="existingCampaigns.length != 0">
          <p style="padding-top:5px; padding-right:5px;">Select a campaign</p>
          <b-select
            size="is-small"
            icon="user"
            rounded
            @input="loadCampaign"
            v-model="activeCampaign"
          >
            <option v-for="option in existingCampaigns" :key="option">{{
              option
            }}</option>
          </b-select>
        </b-field>
      </div>

      <form
        @submit.prevent="submit()"
        style="padding-top:15px;"
        v-if="showFields"
      >
        <div class="columns is-mobile">
          <div class="column">
            <b-field label="Customer" label-position="on-border" required>
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.customer"
              ></b-input>
            </b-field>
            <b-field label="Campaign Label" label-position="on-border" required message="This is the label which will appear in the assessment. Try keep it short.">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.label"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Address 1" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address1"
              ></b-input>
            </b-field>
            <b-field label="Address 2" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address2"
              ></b-input>
            </b-field>
            <b-field label="Address 3" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address3"
              ></b-input>
            </b-field>
            <b-field label="Postal Code" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.postalCode"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Contact Name" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.contactName"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Designation" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.designation"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Email" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.email"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Mobile Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.mobileNumber"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Work Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.workNumber"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Start Date" label-position="on-border">
              <b-input
                readonly
                size="is-small"
                :value="dateString(campaign.startDate)"
              />
              <b-datepicker
                :mobile-native="false"
                position="is-bottom-right"
                v-model="campaign.startDate"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-right="calendar"
                    type="is-primary"
                    size="is-small"
                  ></b-button>
                </template>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field label="End Date" label-position="on-border">
              <b-input
                readonly
                size="is-small"
                :value="dateString(campaign.endDate)"
              />
              <b-datepicker
                :mobile-native="false"
                position="is-bottom-right"
                v-model="campaign.endDate"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-right="calendar"
                    type="is-primary"
                    size="is-small"
                  ></b-button>
                </template>
              </b-datepicker>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-fifth">
            <b-field label="Campaign Scope" label-position="on-border">
              <b-select size="is-small" required v-model="campaign.scope">
                <option v-for="option in scopeOptions" :key="option">{{
                  option
                }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Campaign Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.campaignNumber"
                required
              ></b-input>
            </b-field>
            <b-field
              label="Total Employees"
              label-position="on-border"
              required
            >
              <b-numberinput
                v-model="campaign.totalEmployees"
                controls-position="compact"
                size="is-small"
                step="1"
                :min="0"
              ></b-numberinput>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Campaign Description"
              label-position="on-border"
              required
            >
              <b-input
                type="textarea"
                size="is-small"
                v-model="campaign.description"
              ></b-input>
            </b-field>
          </div>
        </div>

        <div>
          <b-field message="Shift select items to choose multiple" grouped>
            <b-field>
              <b-select
                multiple
                v-model="campaign.assessmentSections"
                size="is-small"
              >
                <option v-for="section in sections" :key="section">{{
                  section
                }}</option>
              </b-select>
            </b-field>
            <b-field>
              <b-taginput
                readonly
                v-model="campaign.assessmentSections"
                size="is-small"
              ></b-taginput>
            </b-field>
          </b-field>
        </div>
        <div style="padding-top:20px;">
          <p class="title is-6">Adhoc Questions</p>
          <b-field style="padding-bottom:20px;" grouped group-multiline>
            <b-button
              size="is-small"
              rounded
              outlined
              type="is-primary"
              icon-left="plus"
              @click="newQuestion()"
              >new question</b-button
            >
          </b-field>
          <div v-if="questions != null && questions.length > 0">
            <campaign-question
              v-for="q in questions"
              :key="q.questionId"
              :qId="q.questionId"
              :type="q.questionType"
              :dropdownOptions="q.dropdownOptions"
              :questionText="q.question"
              :save="saveQuestion"
              @saveQuestion="saveQuestions"
              @deleteQuestion="deleteQuestion"
            ></campaign-question>
          </div>

          <b-button
            type="is-primary"
            native-type="submit"
            class="submitButton"
            outlined
            >{{ buttonText }}</b-button
          >
        </div>
      </form>

      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import { QueryService } from "../../services/query.service";
import CampaignQuestion from "./CampaignQuestion.vue";
export default {
  components: {
    CampaignQuestion,
  },

  data() {
    return {
      isLoading: false,
      questionsEmitted: 0,
      activeCampaign: "",
      questionCount: 0,
      readyToSave: false,
      saveQuestion: false,
      editCampaign: false,
      newCampaign: false,
      showFields: false,
      existingCampaigns: [],
      scopeOptions: ["Whole Company", "Division", "Trial"],
      sections: [
        "Female Health",
        "Cardio",
        "Hypertension",
        "Health Check",
        "Diabetes",
        "Cancer",
        "HIV/TB/STI",
        "Mental Health",
        "Campaign Specific",
      ],

      campaign: {
        customer: "",
        label:"",
        address1: "",
        address2: "",
        address3: "",
        postalCode: "",
        contactName: "",
        designation: "",
        email: "",
        mobileNumber: "",
        workNumber: "",
        startDate: null,
        endDate: null,
        scope: "",
        description: "",
        totalEmployees: null,
        campaignNumber: "",
        questionIds: [],
        assessmentSections: ["Female Health"],
      },
      questions: [],
    };
  },
  methods: {
    clear() {
      this.campaign = {
        customer: "",
        address1: "",
        address2: "",
        address3: "",
        postalCode: "",
        contactName: "",
        designation: "",
        email: "",
        mobileNumber: "",
        workNumber: "",
        startDate: null,
        endDate: null,
        scope: "",
        description: "",
        totalEmployees: null,
        campaignNumber: "",
        questionIds: [],
        assessmentSections: [],
      };
      this.questions = [];
    },
    createNewCampaign() {
      this.showFields = true;
      this.newCampaign = true;
      this.editCampaign = false;
    },
    async editExistingCampaign() {
      if (this.showFields) {
        this.showFields = false;
      }
      this.newCampaign = false;
      this.questionCount = 0;
      this.editCampaign = true;
      try {
        var res = await QueryService.get_campaignNumbers();

        if (res.status == 200) {
          this.existingCampaigns = res.data.campaignNumbers;
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.errorToast(error.response.data.message, 4000);
      }
    },
    async loadCampaign(campaignNum) {
      this.isLoading = true;
      this.questionCount = 0;
      this.showFields = true;
      console.log(campaignNum);

      try {
        let res = await QueryService.get_campaign(campaignNum);
        var campObj = JSON.parse(res.data.campaign);
        if (campObj.startDate) {
          campObj.startDate = new Date(campObj.startDate.$date);
        }
        if (campObj.endDate) {
          campObj.endDate = new Date(campObj.endDate.$date);
        }

        // console.log(campObj.endDate.constructor.name)
        this.campaign = campObj;
        console.log("here");
      } catch (error) {
        console.log(error);
        // if(error){
        // this.errorToast(error.response.data.message, 4000);
        // }
      }
      var qs = await this.loadQuestions(this.campaign.questionIds);
      for (var i = 0; i < qs.length; i++) {
        qs[i] = JSON.parse(qs[i]);
      }
      this.questions = qs;
      this.questionCount = qs.length;
      console.log(this.questions);
      this.isLoading = false;
    },
    async loadQuestions(qIds) {
      try {
        let res = await QueryService.get_Questions(qIds);
        return res.data.questions;
      } catch (error) {
        this.errorToast(error.response.data.message, 4000);
        return [];
      }
    },
    newQuestion() {
      if (this.campaign.campaignNumber == "") {
        this.errorToast(
          "please enter a campaign number above before you continue",
          4500
        );
      } else {
        var id = this.createUniqueId();
        this.questions.push({
          questionId: id,
          questionType: "",
          dropdownOptions: [],
          question: "",
        });
        this.campaign.questionIds.push(id);
        this.questionCount++;
      }
    },
    saveQuestions(questionObj) {
      this.questionsEmitted++;
      console.log(questionObj);
      var qId = questionObj.questionId;
      var exists = false;
      for (var i = 0; i < this.questions.length; i++) {
        if (this.questions[i].questionId == qId) {
          exists = true;
          this.questions[i] = questionObj; //replace with updated question object
        }
      }
      if (!exists) {
        this.questions.push(questionObj);
      }

      if (this.questionsEmitted == this.questionCount) {
        console.log("here");
        this.questionsEmitted = 0;
        this.saveQuestion = false;
        console.log(this.questions);
        this.readyToSave = true;
      }
    },

    deleteQuestion(qId) {
      //this.questions = [];
      for (var i = 0; i < this.campaign.questionIds.length; i++) {
        if (this.campaign.questionIds[i] == qId) {
          this.campaign.questionIds.splice(i, 1);
          this.questionCount--;
        }
      }
      for (i = 0; i < this.questions.length; i++) {
        if (this.questions[i].questionId == qId) {
          this.questions.splice(i, 1);
        }
      }
    },

    createUniqueId() {
      //creates a unique question Id
      var unique = false;
      var length = this.campaign.questionIds.length;
      var id = this.campaign.campaignNumber + "-" + length;

      while (!unique) {
        //break when unique is true
        id = this.campaign.campaignNumber + "-" + length;
        unique = true;
        for (var i = 0; i < this.campaign.questionIds.length; i++) {
          if (id == this.campaign.questionIds[i]) {
            unique = false;
            length++;
          }
        }
      }

      return id;
    },

    submit() {
      if (this.editCampaign) {
        this.saveQuestion = true;
        this.readyToSave = true;
      }
      if (this.newCampaign) {
        if (this.questionCount > 0) {
          this.saveQuestion = true;
        } else {
          this.readyToSave = true;
        }
      }
    },
    async saveToDB() {
      this.saveQuestion = false;
      this.readyToSave = false;
      var res1 = null;
      var res2 = null;
      this.isLoading = true;
      try {
        res1 = await QueryService.save_Campaign(this.campaign);
        this.successToast(res1.data.message, 3000);
      } catch (error) {
        this.errorToast(error.response.data.message, 4000);
      }

      if (this.questions.length > 0 && res1.status == 200) {
        try {
          res2 = await QueryService.save_Questions(this.questions);
          this.successToast(res2.data.message, 3000);
        } catch (error) {
          this.errorToast(error.response.data.message, 4000);
        }
      }
      this.isLoading = false;
      //this.questions = []; //empty questions for next campaign save
      this.clear();
    },
    async updateCampaign() {
      this.isLoading = true;
      var success = false;
      try {
        let res = await QueryService.update_Campaign(this.campaign);
        this.successToast(res.data.message, 3000);
        success = true;
      } catch (error) {
        console.log(error);
        this.errorToast(error.response.data.message, 4000);
        this.isLoading = false;
      }

      if (success) {
        try {
          let res = await QueryService.update_Questions(this.questions);
          this.successToast(res.data.message, 3000);
        } catch (error) {
          console.log(error);
          this.errorToast(error.response.data.message, 4000);
          this.isLoading = false;
        }
      }
      this.isLoading = false;
    },
    dateString(dateObj) {
      if (dateObj instanceof Date) {
        var dateArray = this.convertDateObject(dateObj);
        return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
      } else {
        return "";
      }
    },
  },
  computed: {
    buttonText() {
      if (this.newCampaign) {
        return "create campaign";
      }
      if (this.editCampaign) {
        return "save campaign";
      }
      return "";
    },
  },
  watch: {
    readyToSave: function() {
      if (this.readyToSave) {
        if (this.newCampaign) {
          this.saveToDB();
        }
        if (this.editCampaign) {
          this.updateCampaign();
        }
        this.readyToSave = false;
      }
    },
  },

  async created() {},
};
</script>

<style scoped></style>
