

const state = {
    mentalHealthData: null
}

const getters = {
    getMentalHealthData: (state) => {
        return state.mentalHealthData;
    }
}

const actions = {
    saveMentalHealthData(context, payload) {
        context.commit('saveMentalHealthData', payload);
    },
    clearMentalHealth(context){
        context.commit('clearMentalHealth')
    }


}

const mutations = {
    saveMentalHealthData(state, payload) {
        state.mentalHealthData = payload;
    },
    clearMentalHealth(state){
        state.mentalHealthData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}