import { ApiService, handleAxiosError } from "./api.service";
import { TokenService } from "./storage.service";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async function(email, password) {
    const requestData = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        email: email,
        password: password,
      },
    };
    try {
      let response = await ApiService.post("/user/login", requestData.data, {
        headers: requestData.headers,
      });
      TokenService.saveToken("access_token", response.data.access_token);
      TokenService.saveUser(response.data.first_name);
      TokenService.saveRefreshToken(response.data.refresh_token);
      ApiService.setHeader("access_token");
      return response;
    } catch (error) {
      const errorObj = handleAxiosError(error);
      console.log(errorObj);
      return errorObj;
      //throw new AuthenticationError(errorObj.status, errorObj.data.detail || '')
    }
  },

  register: async function(userObject) {
    console.log("registering...");
    const requestData = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userObject,
    };
    try {
      let res = await ApiService.post("/user/register", requestData.data, {
        headers: requestData.headers,
      });
      return res;
    } catch (error) {
      const errorObj = handleAxiosError(error);
      return errorObj;
      //throw new AuthenticationError(errorObj.status, errorObj.data.message || '')
    }
  },

  /**
   * Refresh the access token.
   **/
  refreshToken: async function() {
    const refreshToken = TokenService.getRefreshToken();

    const requestData = {
      method: "post",
      url: "auth",
      data: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setHeader();

      return response.data.access_token;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },
  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken('access_token');
    TokenService.removeUser();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
  },
};

export default UserService;

export { UserService, AuthenticationError };
