<template>
  <b-field grouped>
    <b-field position="is-left" v-if="backEnabled">
      <b-button
        id="back"
        @click="back()"
        outlined
        type="is-secondary"
        size="is-small"
        rounded
        >back</b-button
      >
      <!-- <p type="is-secondary" id="back" expanded @click="back()">&#60;back</p> -->
    </b-field>

    <b-field expanded>
      <b-button
        id="clear"
        @click="clear()"
        outlined
        type="is-secondary"
        size="is-small"
        rounded
        >clear</b-button
      >
      <!-- <p type="is-secondary" id="clear" @click="clear()">clear</p> -->
    </b-field>

    <b-field v-if="nextEnabled" position="is-right" expanded>
      <b-button
        id="next"
        @click="next()"
        outlined
        type="is-secondary"
        size="is-small"
        rounded
        >{{ skipButtonText }}</b-button
      >
      <!-- <p id="next" @click="next()">next&#62;</p> -->
    </b-field>
  </b-field>
</template>

<script>
export default {
  props: { backEnabled: Boolean, nextEnabled: Boolean, source: String },

  data() {
    return {
      sections: [],
      normalOrder: [
        "Cardio",
        "Numbers",
        "Hypertension",
        "Diabetes",
        "Cancer",
        "HIV",
        "MentalHealth",
        "FemaleHealth",
        "Adhoc",
      ],
    };
  },

  computed: {
    skipButtonText() {
      // return finish if its the last page in the assessment
      return this.source == "Numbers" ? "Finish" : "Skip";
    },
  },

  methods: {
    back2() {
      if (this.sections != null) {
        var index = 0;
        for (var i = 0; i < this.sections.length; i++) {
          if (this.source == this.sections[i]) {
            index = i - 1;
          }
        }
        if (index < 0) {
          index = 0;
        }
        var name = this.sections[index];
        if (name == "Health Check") {
          name = "Numbers";
        }
        if (name == "HIV/TB/STI") {
          name = "HIV";
        }
        if (name == "Campaign Specific") {
          name = "Adhoc";
        }
        this.$router.push({ name: name });
      }
    },

    next2() {
      if (this.sections != null) {
        var index = 0;
        for (var i = 0; i < this.sections.length; i++) {
          if (this.source == this.sections[i]) {
            index = i + 1;
          }
        }
        if (index > this.sections.length) {
          index = this.sections.length;
        }
        var name = this.sections[index];
        if (name == "Health Check") {
          name = "Numbers";
        }
        if (name == "HIV/TB/STI") {
          name = "HIV";
        }
        if (name == "Campaign Specific") {
          name = "Adhoc";
        }
        this.$router.push({ name: name });
      }
    },

    back() {
      switch (this.source) {
        case "Personal":
          this.$router.push({ name: "Home" });
          break;
        case "HIV":
          this.$router.push({ name: "Cancer" });
          break;
        case "Cardio":
          this.$router.push({ name: "Personal" });
          break;
        case "Hypertension":
          this.$router.push({ name: "Cardio" });
          break;
        case "Diabetes":
          this.$router.push({ name: "Hypertension" });
          break;
        case "Cancer":
          this.$router.push({ name: "Diabetes" });
          break;
        case "Numbers":
          this.$router.push({ name: "FemaleHealth" });
          break;
        case "MentalHealth":
          this.$router.push({ name: "HIV" });
          break;
        case "FemaleHealth":
          this.$router.push({ name: "MentalHealth" });
          break;
        case "Adhoc":
          this.$router.push({ name: "FemaleHealth" });
          break;
        default:
          break;
      }
    },
    next() {
      switch (this.source) {
        case "Personal":
          this.$router.push({ name: "Cardio" });
          break;
        case "Cardio":
          this.$router.push({ name: "Hypertension" });
          break;
        case "Hypertension":
          this.$router.push({ name: "Diabetes" });
          break;
        case "Diabetes":
          this.$router.push({ name: "Cancer" });
          break;
        case "Cancer":
          this.$router.push({ name: "HIV" });
          break;
        case "Numbers":
          this.$router.push({ name: "SummaryReport" });
          break;
        case "HIV":
          this.$router.push({ name: "MentalHealth" });
          break;
        case "MentalHealth":
          this.$router.push({ name: "FemaleHealth" });
          break;
        case "FemaleHealth":
          this.$router.push({ name: "Numbers" });
          break;
        default:
          break;
      }
    },
    clear() {
      this.$emit("clearFields");
      switch (this.source) {
        case "Personal":
          this.$store.dispatch("clearUserDetails");
          break;
        case "Cardio":
          this.$store.dispatch("clearCardio");
          break;
        case "Hypertension":
          this.$store.dispatch("clearHypertension");
          break;
        case "Diabetes":
          this.$store.dispatch("clearDiabetes");
          break;
        case "Cancer":
          this.$store.dispatch("clearCancer");
          break;
        case "HIV":
          this.$store.dispatch("clearHIV");
          break;
        case "Adhoc":
          //this.$store.dispatch("clearCancer");
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.sections = null;
    this.sections = this.$store.getters.getCampaignSections;
  
  },
};
</script>

<style lang="scss" scoped>
#back {
  cursor: pointer;
}
#next {
  cursor: pointer;
  position: relative;
  float: right;
}
#clear {
  cursor: pointer;
  // text-decoration: underline;
  position: relative;
}
</style>
