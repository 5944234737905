<template>
  <img
    class=""
    alt="logo"
    :src="logoPathForClient"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  data() {
    return {
      clientName: "default",
    };
  },
  props: {
    width: Number,
    height: Number,
  },
  computed: {
    logoPathForClient() {
      return require(`@/assets/logos/${this.clientName}/logo.svg`);
    },
  },
  created() {
    this.clientName = this.$route.params.client || "default";
  },
};
</script>

