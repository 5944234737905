<template>
  <div>
    <!-- Your template goes here -->
    <div class="columns">
      <div class="column is-half">
        <b-field label="Business Unit">
          <b-input
            type="text"
            id="business_unit"
            v-model="personal_details.business_unit"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field label="Job Type">
          <b-select id="job_type" v-model="personal_details.job_type">
            <option value="office">Office</option>
            <option value="foreman">Foreman</option>
            <option value="machine_operator">Machine operator</option>
            <option value="driver">Driver</option>
            <option value="heavy_manual_labour">Heavy manual labour</option>
            <option value="moderate_manual_labour">Moderate manual labour</option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Do you belong to a Medical Aid?">
          <b-checkbox
            id="on_medical_aid"
            v-model="personal_details.on_medical_aid"
          ></b-checkbox>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CareworksPersonalComponent",
  data() {
    return {
      personal_details: {
        business_unit: "",
        job_type: "",
        on_medical_aid: false,
      },
    };
  },
  props: {
    default_careworks_personal_details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Your component methods go here
  },
  computed: {
    // Your computed properties go here
  },
  watch: {
    personal_details: function () {
      this.$emit("valueChanged", this.personal_details);
    },
  },
  created() {
    this.personal_details = this.default_careworks_personal_details;
  },
  mounted() {
    // Your mounted lifecycle hook goes here
  },
};
</script>

<style scoped>
/* Your component styles go here */
</style>