

const state =  {
    hypertensionData:null,
} 

const getters = {
    getHypertensionData:(state)=>{
        return state.hypertensionData;
    },
    getHypertensionDiagnosis:(state)=>{
        return state.hypertensionData.diagnosed;
    }


}

const actions = {
    saveHypertensionData(context, payload){
        context.commit('saveHypertensionData', payload)
    },
    clearHypertension(context){
        context.commit('clearHypertension')
    }

}

const mutations = {
    saveHypertensionData(state, payload){
        state.hypertensionData = payload;
    },
    clearHypertension(state){
        state.hypertensionData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}