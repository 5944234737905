const state = {
  campaign: null,
  questions: null,
  sections: null,
  visibleSections: null,
};

const getters = {
  getCampaign: (state) => {
    return state.campaign;
  },
  getLabel:(state)=>{
    if (state.campaign != null){
      if(state.campaign.label==""){
        return "Other"
      }
      return state.campaign.label;
    }else{
      return "Other"
    }
  },
  getCampaignNumber: (state) => {
    if (state.campaign != null) {
      return state.campaign.campaignNumber;
    } else {
      return null;
    }
  },
  getCampaignSections: (state) => {
    if (state.campaign != null) {
      return state.campaign.assessmentSections;
    } else {
      return null;
    }
  },
  getQuestions: (state) => {
    if (state.questions != null) {
      return state.questions;
    } else {
      return null;
    }
  },
  getSections: (state) => {
    if (state.sections != null) {
      return state.sections;
    } else {
      return null;
    }
  },
  getVisibleSections:(state)=>{
    if (state.visibleSections != null) {
      return state.visibleSections;
    } else {
      return null;
    }
  }
};

const actions = {
  saveCampaign(context, payload) {
    context.commit("saveCampaign", payload);
  },
  saveQuestions(context, payload) {
    context.commit("saveQuestions", payload);
  },

  saveSections(context, payload) {
    context.commit("saveSections", payload);
  },
  clearCampaign(context) {
    context.commit("clearCampaign");
  },
  setVisibleSections(context, payload) {
    context.commit('setVisibleSections', payload)
  }

};

const mutations = {
  saveCampaign(state, payload) {
    state.campaign = payload;
  },
  saveQuestions(state, payload) {
    state.questions = payload;
  },
  saveSections(state, payload) {
    state.sections = payload;
  },
  clearCampaign(state) {
    state.campaign = null;
    state.questions = null;
    state.sections = null;
  },
  setVisibleSections(state, payload) {
    state.visibleSections = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
