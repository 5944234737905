//import { ApiService, handleAxiosError } from "./api.service";
import axios from "axios";
import { TokenService } from "./storage.service";

const smsURI = process.env.VUE_APP_SMSPORTAL_HOST;
const clientID = process.env.VUE_APP_SMSPORTAL_CLIENT_ID;
const apiSecret = process.env.VUE_APP_SMSPORTAL_API_SECRET;

var smsInstance = axios.create({
  baseURL: smsURI,
  timeout: 2000,
});

const smsService = {
  getAccessToken: async function() {
    var encodedAuthToken = window.btoa(clientID + ":" + apiSecret);
    var headers = {
      //headers on this request only
      Authorization: `Basic ${encodedAuthToken}`,
      Accept: "application/json",
    };
    let res = await smsInstance.get("/Authentication",{ headers: headers });
    if (res.status == 200) {
      TokenService.saveToken("sms_access_token",res.data.token, res.data.expiresInMinutes + "min"); //must use min suffix to tell VueCookies the units
    } else {
      console.log("Error accessing SMS PORTAL: ", res.status);
    }
  },

  sendSMS: async function(messages) {
    if (!TokenService.getToken("sms_access_token")){
       await this.getAccessToken()
    }
    var headers = {
      //headers on this request only
      Authorization: `Bearer ${TokenService.getToken("sms_access_token")}`,
      Accept: "application/json",
    };
    let res = await smsInstance.post("/bulkmessages",{Messages: messages },{ headers: headers});
    if (res.status == 200){
        console.log("SMS messages sent.")
    }else{
        console.log("Error sending SMS messages: ", res.status)
    }

    return res
  },

};

export default smsService;
export { smsService };
