

const state =  {
    diabetesData:null,
} 

const getters = {
    getDiabetesData:(state)=>{
        return state.diabetesData;
    }

}

const actions = {
    saveDiabetesData(context, payload){
        context.commit('saveDiabetesData', payload)
    },
    clearDiabetes(context){
        context.commit('clearDiabetes')
    }

}

const mutations = {
    saveDiabetesData(state, payload){
        state.diabetesData = payload;
    },
    clearDiabetes(state){
        state.diabetesData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}