<template>
  <div>
    <nav-bar></nav-bar>
    <div
      class="has-text-left container"
      style="
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 150px;
        padding-left: 20px;
      "
    >
      <b-field grouped>
        <p class="title is-5">Health Check</p>
      </b-field>

      <b-field
        ><p id="info" @click="showInfo()">
          {{ infoHeading }}
        </p></b-field
      >
      <b-field style="color: $primary; width: device-width" v-if="infoVisible"
        ><p>
          {{ infoText }} <b> You can leave out the answers you don't know. </b>
        </p></b-field
      >

      <the-nav-buttons
        source="Numbers"
        :backEnabled="true"
        :nextEnabled="true"
        @clearFields="clear()"
      ></the-nav-buttons>

      <b-message
        type="is-primary"
        aria-close-label="Close message"
        v-if="!visible"
      >
        You do not need to complete this section of this assessment
      </b-message>

      <form
        @submit.prevent="submitNumbers()"
        style="padding-top: 15px"
        v-if="visible"
      >
        <div>
          <div class="columns is-desktop">
            <div class="column">
              <b-field grouped group-multiline>
                <b-field label="Height (m)">
                  <b-tooltip
                    label="Height is measured in metres (m), for example 1,7"
                    type=" is-light"
                    position="is-bottom"
                  >
                    <b-numberinput
                      v-model="numbers.height"
                      controls-alignment="right"
                      controls-position="compact"
                      size="is-small"
                      step="0.01"
                      :min="0"
                      :max="2.4"
                    ></b-numberinput>
                  </b-tooltip>
                </b-field>

                <b-field label="Weight (kg)">
                  <b-tooltip
                    label="Weight is measured in kilograms (kg), for example 70"
                    type=" is-light"
                    position="is-bottom"
                  >
                    <b-numberinput
                      v-model="numbers.weight"
                      controls-alignment="right"
                      controls-position="compact"
                      size="is-small"
                      :min="0"
                      :max="200"
                    ></b-numberinput>
                  </b-tooltip>
                </b-field>

                <b-field label="Weight Date">
                  <b-input
                    readonly
                    :value="dateString(numbers.weightDate)"
                    size="is-small"
                  />
                  <b-datepicker
                    v-model="numbers.weightDate"
                    :mobile-native="false"
                    :max-date="maxDate"
                    position="is-bottom-right"
                  >
                    <template v-slot:trigger>
                      <b-button
                        icon-right="calendar"
                        type="is-primary"
                        size="is-small"
                      ></b-button>
                    </template>
                  </b-datepicker>
                </b-field>
              </b-field>
            </div>

            <!-- <div class="column">
            <b-field grouped group-multiline> </b-field>
          </div> -->
            <div class="column">
              <b-field grouped group-multiline>
                <b-field label="BMI">
                  <b-tooltip
                    multilined
                    label="BMI stands for Body Mass Index. It is used to measure weight status by comparing a person’s weight with their height. BMI is influenced by several factors such as age, sex and bone structure."
                    type=" is-light"
                    position="is-bottom"
                  >
                    <b-input
                      size="is-small"
                      :value="computeBMI"
                      readonly
                    ></b-input>
                  </b-tooltip>
                </b-field>
              </b-field>
            </div>
          </div>

          <b-field style="padding-top: 30px">
            <b-field
              label="If you don’t know your weight, which of the following categories describes you best?"
              ><b-select size="is-small" v-model="numbers.weightDescription">
                <option>underweight</option>
                <option>healthy weight</option>
                <option>overweight</option>
                <option>very overweight</option>
              </b-select></b-field
            >
          </b-field>

          <hr />
        </div>

        <b-field>
          <p class="title is-6">Blood Pressure</p>
        </b-field>

        <b-field grouped>
          <b-field>
            <p id="info" @click="showBpInfo()">
              {{ bpInfoVisbile ? "hide" : "more information" }}
            </p>
          </b-field>
          <b-field>
            <p id="info" @click="bpSectionVisible = !bpSectionVisible">
              {{ bpSectionVisible ? "skip this section" : "show this section" }}
            </p>
          </b-field>
        </b-field>
        <b-field style="color: $primary" v-if="bpInfoVisbile"
          ><p>{{ infoBpText }}</p></b-field
        >

        <div v-if="bpSectionVisible">
          <b-field grouped group-multiline>
            <b-field label="SBP">
              <b-numberinput
                v-model="numbers.SBP"
                controls-alignment="right"
                controls-position="compact"
                size="is-small"
                step="1.0"
                :min="80"
                :max="240"
              ></b-numberinput>
            </b-field>
            <b-field label="DBP">
              <b-numberinput
                v-model="numbers.DBP"
                controls-alignment="right"
                controls-position="compact"
                size="is-small"
                step="1.0"
                :min="50"
                :max="130"
              ></b-numberinput>
            </b-field>

            <b-field label="Measurement Date">
              <b-input
                readonly
                :value="dateString(numbers.bpDate)"
                size="is-small"
              />
              <b-datepicker
                v-model="numbers.bpDate"
                :mobile-native="false"
                :max-date="maxDate"
                position="is-bottom-right"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-right="calendar"
                    type="is-primary"
                    size="is-small"
                  ></b-button>
                </template>
              </b-datepicker>
            </b-field>
          </b-field>

          <div class="columns is-desktop" style="padding-top: 30px">
            <div class="column auto">
              <p>
                Are you taking prescribed medication to control your blood
                pressure?
              </p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-radio
                  v-model="numbers.bpMedication"
                  name="bpMedication"
                  native-value="yes"
                >
                  yes
                </b-radio>
                <b-radio
                  v-model="numbers.bpMedication"
                  name="bpMedication"
                  native-value="no"
                >
                  no
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns is-desktop">
            <div class="column auto">
              <p>Where did you have your blood pressure measured?</p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-select v-model="numbers.bpLocation" size="is-small">
                  <option
                    v-for="option in bpMeasurementLocations"
                    :key="option"
                  >
                    {{ option }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <hr />
        <b-field>
          <p class="title is-6">Blood Glucose</p>
        </b-field>

        <b-field grouped>
          <b-field>
            <p id="info" @click="showGlucoseInfo()">
              {{ glucoseInfoVisbile ? "hide" : "more information" }}
            </p>
          </b-field>
          <b-field>
            <p id="info" @click="bgSectionVisible = !bgSectionVisible">
              {{ bgSectionVisible ? "skip this section" : "show this section" }}
            </p>
          </b-field>
        </b-field>
        <b-field style="color: $primary" v-if="glucoseInfoVisbile"
          ><p>{{ infoGlucoseText }}</p></b-field
        >
        <div v-if="bgSectionVisible">
          <div class="columns is-desktop">
            <div class="column">
              <b-field grouped group-multiline>
                <b-field label="Random">
                  <b-numberinput
                    v-model="numbers.bgRandom"
                    controls-alignment="right"
                    controls-position="compact"
                    size="is-small"
                    step="0.1"
                    :min="1.0"
                    :max="30.0"
                  ></b-numberinput>
                </b-field>
                <b-field label="Fasting">
                  <b-numberinput
                    v-model="numbers.bgFasting"
                    controls-alignment="right"
                    controls-position="compact"
                    size="is-small"
                    step="0.1"
                    :min="1.0"
                    :max="30.0"
                  ></b-numberinput>
                </b-field>

                <b-field label="Measurement Date">
                  <b-input
                    readonly
                    :value="dateString(numbers.bgDate)"
                    size="is-small"
                  />
                  <b-datepicker
                    v-model="numbers.bgDate"
                    :mobile-native="false"
                    :max-date="maxDate"
                    position="is-bottom-right"
                  >
                    <template v-slot:trigger>
                      <b-button
                        icon-right="calendar"
                        type="is-primary"
                        size="is-small"
                      ></b-button>
                    </template>
                  </b-datepicker>
                </b-field>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field grouped group-multiline>
                <b-field label="HbaA1c (%)">
                  <b-input
                    type="number"
                    step="0.01"
                    size="is-small"
                    v-model.trim="numbers.bgHbaA1c"
                  ></b-input>
                </b-field>
                <b-field label="Measurement Date">
                  <b-input
                    readonly
                    :value="dateString(numbers.hba1cDate)"
                    size="is-small"
                  />
                  <b-datepicker
                    v-model="numbers.hba1cDate"
                    :mobile-native="false"
                    :max-date="maxDate"
                    position="is-bottom-left"
                  >
                    <template v-slot:trigger>
                      <b-button
                        icon-right="calendar"
                        type="is-primary"
                        size="is-small"
                      ></b-button>
                    </template>
                  </b-datepicker>
                </b-field>
              </b-field>
            </div>
          </div>

          <div class="columns is-desktop" style="padding-top: 30px">
            <div class="column auto">
              <p>
                Are you taking prescribed medication to control your blood
                sugar?
              </p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-radio
                  v-model="numbers.bgMedication"
                  name="bgMeds"
                  native-value="yes"
                >
                  yes
                </b-radio>
                <b-radio
                  v-model="numbers.bgMedication"
                  name="bgMeds"
                  native-value="no"
                >
                  no
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns is-desktop">
            <div class="column auto">
              <p>Where did you have your blood sugar measured?</p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-select v-model="numbers.bgLocation" size="is-small">
                  <option
                    v-for="option in bpMeasurementLocations"
                    :key="option"
                  >
                    {{ option }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <hr />

        <b-field>
          <p class="title is-6">Cholesterol</p>
        </b-field>

        <b-field grouped>
          <b-field>
            <p id="info" @click="showCholInfo()">
              {{ cholInfoVisbile ? "hide" : "more information" }}
            </p>
          </b-field>
          <b-field>
            <p id="info" @click="cholSectionVisible = !cholSectionVisible">
              {{
                cholSectionVisible ? "skip this section" : "show this section"
              }}
            </p>
          </b-field>
        </b-field>
        <b-field style="color: $primary" v-if="cholInfoVisbile"
          ><p>{{ infoCholText }}</p></b-field
        >
        <div v-if="cholSectionVisible">
          <b-field grouped group-multiline>
            <b-field expanded style="padding-top: 0px">
              <b-field label="Random">
                <b-numberinput
                  v-model="numbers.cholesterol"
                  controls-alignment="right"
                  controls-position="compact"
                  size="is-small"
                  step="0.1"
                  :min="2.0"
                  :max="15.0"
                ></b-numberinput>
              </b-field>

              <b-field label="Measurement Date">
                <b-input
                  readonly
                  :value="dateString(numbers.cholesterolDate)"
                  size="is-small"
                />
                <b-datepicker
                  v-model="numbers.cholesterolDate"
                  :mobile-native="false"
                  :max-date="maxDate"
                  position="is-bottom-right"
                >
                  <template v-slot:trigger>
                    <b-button
                      icon-right="calendar"
                      type="is-primary"
                      size="is-small"
                    ></b-button>
                  </template>
                </b-datepicker>
              </b-field>
            </b-field>
          </b-field>

          <div class="columns is-desktop" style="padding-top: 30px">
            <div class="column auto">
              <p>
                Are you taking prescribed medication to control your cholestrol?
              </p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-radio
                  v-model="numbers.cholesterolMedication"
                  name="cholMed"
                  native-value="yes"
                >
                  yes
                </b-radio>
                <b-radio
                  v-model="numbers.cholesterolMedication"
                  name="cholMed"
                  native-value="no"
                >
                  no
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns is-desktop">
            <div class="column auto">
              <p>Where did you have your cholesterol measured?</p>
            </div>
            <div class="column is-one-quarter">
              <b-field>
                <b-select v-model="numbers.cholesterolLocation" size="is-small">
                  <option
                    v-for="option in bpMeasurementLocations"
                    :key="option"
                  >
                    {{ option }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <hr />

        <b-field>
          <p class="title is-6">Chronic Diseases</p>
        </b-field>

        <b-field grouped>
          <b-field>
            <p id="info" @click="showChronicInfo()">
              {{ chronicInfoVisible ? "hide" : "more information" }}
            </p>
          </b-field>
          <b-field>
            <p
              id="info"
              @click="chronicSectionVisible = !chronicSectionVisible"
            >
              {{
                chronicSectionVisible
                  ? "skip this section"
                  : "show this section"
              }}
            </p>
          </b-field>
        </b-field>
        <b-field style="color: $primary" v-if="chronicInfoVisible"
          ><p>{{ infoChronicText }}</p></b-field
        >
        <div v-if="chronicSectionVisible">
          <div class="columns is-desktop">
            <div class="column">
              <b-field expanded>
                <b-field
                  message="E.g. heart disease, asthma, thyroid, arthritis"
                >
                  <p style="padding-right: 10px; padding-top: 5px">
                    Are you taking any prescribed medication to control other
                    chronic diseases?
                  </p>
                </b-field>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field
                expanded
                message="Enter the names of your medication. Enter a comma seperated list"
              >
                <b-taginput
                  v-model="numbers.prescriptionMeds"
                  ellipsis
                  aria-close-label="Delete this tag"
                >
                </b-taginput>
              </b-field>
            </div>
          </div>
        </div>

        <b-field position="is-right">
          <b-button
            type="is-primary"
            outlined
            native-type="submit"
            class="submitButton"
            >submit and finish</b-button
          >

          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { QueryService } from "../../services/query.service";
import NavBar from "../ui/NavBar.vue";
import TheNavButtons from "../ui/TheNavButtons.vue";
export default {
  components: {
    NavBar,
    TheNavButtons,
  },
  data() {
    const today = new Date();
    return {
      visible: true,
      infoHeading: "hide",
      infoText: `BMI stands for Body Mass Index. It is used to measure weight status by comparing a person’s weight with their height. BMI is influenced by several factors such as age, sex and bone structure. 
              BMI Weight Status:
              Less than 18.5 (Underweight)
              18.5 to 24.9 (Healthy)
              25 to 29.9 (Overweight)
              30 and above (Very Overweight).
              We recommend you - try and achieve 20-30 minutes of exercise (like brisk walking) 5 times a week - maintain a healthy diet with fruits and vegetables - visit your healthcare provider in the next 3 months to check your blood pressure, glucose, BMI and cholesterol.`,
      infoBp: "more information",
      infoBpText:
        "Blood Pressure readings are recorded as two numbers, for example 120 and 80, and written as 120/80. The higher (first) number is called the Systolic Blood Pressure (SBP). This refers to the amount of pressure in your arteries when your heart muscle contracts to pump blood. The lower (second) figure is the Diastolic Blood Pressure (DBP). This is the amount of pressure when your heart is between beats. Both numbers are important in establishing the health of your heart.",
      bpInfoVisbile: true,
      infoGlucose: "more information",
      infoGlucoseText:
        "Blood Glucose tests measure the sugar levels in your blood. We use it to measure your risk for developing Diabetes. Fasting Glucose tests was done after not eating or drinking anything for the previous 6 hours. If you are not sure if your test was a fasting test, capture your result in the 'Random Glucose' box. The HbA1c is a blood test for Diabetics on treatment. It measures the amount of Glucose that is bound to Haemaglobin (red pigment in blood). It is a useful indicator of longer term control of Diabetes.",
      glucoseInfoVisbile: true,
      infoChol: "more information",
      infoCholText:
        "Cholesterol is one of the measures we use to determine the risk for cardiovascular disease. Cholesterol can build up in your arteries, causing narrowing and making it harder for blood to flow (circulatory problems in the coronary arteries that feed the heart muscle). This build-up is called plaque and can lead to heart attacks and strokes.",
      cholInfoVisbile: true,
      infoChronic: "more information",
      infoChronicText:
        "Knowing what medication you are taking will help our CareWorks nurse better understand your health risk.",
      chronicInfoVisible: true,

      gender: "",
      bpMeasurementLocations: [
        "Home",
        "Doctor",
        "Clicks",
        "Dischem",
        "Pharmacy",
        "Workplace",
        "Clinic",
      ],
      diseases: ["Heart Disease", "Asthma", "Thyroid", "Arthritis", "Other"],
      isLoading: false,
      infoVisible: true,
      bpSectionVisible: true,
      bgSectionVisible: true,
      cholSectionVisible: true,
      chronicSectionVisible: true,
      numbers: {
        reference_num: "",
        weight: null,
        weightDate: null,
        height: null,
        bmi: null,
        weightDescription: "",
        bmiDate: null,
        SBP: null,
        DBP: null,
        bpDate: null,
        bpMedication: null,
        bpLocation: "",
        bgRandom: null,
        bgFasting: null,
        bgDate: null,
        bgHbaA1c: 0,
        hba1cDate: null,
        bgMedication: "",
        bgLocation: "",
        cholesterol: null,
        cholesterolDate: null,
        cholesterolMedication: null,
        cholesterolLocation: "",
        prescriptionMeds: [],
        glucoseRiskcat: "",
        hba1cRiskcat: "",
        cholesterolRiskcat: "",
        bmiRiskcat: "",
        bpRiskcat: "",
        bpMessage: "",
        bmiMessage: "",
        glucoseMessage: "",
        hba1cMessage: "",
        cholMessage: "",
        bpNurseTask: "",
        bgNurseTask: "",
        cholesterolNurseTask: "",
        bmiNurseTask: "",
        hba1cNurseTask: "",
        bpScreen: "",
        bgScreen: "",
        cholesterolScreen: "",
        bmiScreen: "",
        hba1cScreen: "",
      },
      maxDate: new Date(
        today.getFullYear(), //assuming some minimum age of 10?
        today.getMonth(),
        today.getDate()
      ),
    };
  },

  methods: {
    async submitNumbers() {
      if (
        this.numbers.SBP != null &&
        this.numbers.DBP != null &&
        this.numbers.SBP < this.numbers.DBP
      ) {
        this.errorToast("SBP must be greater than DBP", 4000);
      } else {
        this.numbers.bmiDate = this.numbers.weightDate;
        this.numbers.bmi = this.computeBMI;
        this.numbers.reference_num = this.$store.getters.getRefNum;
        this.isLoading = true;
        await this.computeRisk(); //also gets action messages

        let res = await QueryService.update_withNumbers(this.numbers);
        if (res.status == 200) {
          this.successToast(res.data.message, 2000);
          this.$store.dispatch("setNumbersFinished", true);
          this.$router.push({ name: "SummaryReport" });
        } else {
          this.errorToast("error saving health check data", 3000);
          console.log(res.data.message);
        }
        this.isLoading = false;
      }
    },
    dateString(dateObj) {
      if (dateObj instanceof Date) {
        var dateArray = this.convertDateObject(dateObj);
        return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
      } else {
        return "";
      }
    },
    clear() {
      this.numbers = {
        reference_num: "",
        weight: null,
        weightDate: null,
        height: null,
        bmi: null,
        weightDescription: "",
        bmiDate: null,
        SBP: null,
        DBP: null,
        bpDate: null,
        bpMedication: null,
        bpLocation: "",
        bgRandom: null,
        bgFasting: null,
        bgDate: null,
        bgHbaA1c: 0,
        hba1cDate: null,
        bgMedication: "",
        bgLocation: "",
        cholesterol: null,
        cholesterolDate: null,
        cholesterolMedication: null,
        cholesterolLocation: "",
        prescriptionMeds: [],
        glucoseRiskcat: "",
        hba1cRiskcat: "",
        cholesterolRiskcat: "",
        bmiRiskcat: "",
        bpRiskcat: "",
        bpMessage: "",
        bmiMessage: "",
        glucoseMessage: "",
        hba1cMessage: "",
        cholMessage: "",
        bpNurseTask: "",
        bgNurseTask: "",
        cholesterolNurseTask: "",
        bmiNurseTask: "",
        hba1cNurseTask: "",
        bpScreen: "",
        bgScreen: "",
        cholesterolScreen: "",
        bmiScreen: "",
        hba1cScreen: "",
      };
    },
    showInfo() {
      this.infoVisible = !this.infoVisible;
      if (this.infoVisible) {
        this.infoHeading = "hide";
      } else {
        this.infoHeading = "more information";
      }
    },
    showBpInfo() {
      this.bpInfoVisbile = !this.bpInfoVisbile;
      if (this.bpInfoVisbile) {
        this.infoBp = "hide";
      } else {
        this.infoBp = "more information";
      }
    },
    showCholInfo() {
      this.cholInfoVisbile = !this.cholInfoVisbile;
      if (this.cholInfoVisbile) {
        this.infoChol = "hide";
      } else {
        this.infoChol = "more information";
      }
    },
    showGlucoseInfo() {
      this.glucoseInfoVisbile = !this.glucoseInfoVisbile;
      if (this.glucoseInfoVisbile) {
        this.infoGlucose = "hide";
      } else {
        this.infoGlucose = "more information";
      }
    },
    showChronicInfo() {
      this.chronicInfoVisible = !this.chronicInfoVisible;
      if (this.chronicInfoVisible) {
        this.infoChronic = "hide";
      } else {
        this.infoChronic = "more information";
      }
    },

    async computeRisk() {
      var hbScore = "";
      var glucoseScore = "";
      var cholScore = "";
      var bmiScore = "";
      var bpScore = "";

      if (
        (this.numbers.bgRandom > 5.6 || this.numbers.bgFasting > 5.6) &&
        (this.numbers.bgRandom < 11 || this.numbers.bgFasting < 11)
      ) {
        this.numbers.glucoseRiskcat = "pre diabetic";
        glucoseScore = ">5.6<11";
      } else if (this.numbers.bgRandom > 11 || this.numbers.bgFasting > 11) {
        this.numbers.glucoseRiskcat = "diabetic";
        glucoseScore = ">11";
      } else {
        this.numbers.glucoseRiskcat = "low risk";
        glucoseScore = "<5.6";
      }

      if (this.numbers.bgHbaA1c == 0 || isNaN(this.numbers.bgHbaA1c)) {
        this.numbers.hba1cRiskcat = "no readings";
        hbScore = "";
      } else if (this.numbers.bgHbaA1c > 6.4) {
        this.numbers.hba1cRiskcat = "uncontrolled";
        hbScore = ">6.4";
      } else if (this.numbers.bgHbaA1c < 5.7) {
        this.numbers.hba1cRiskcat = "controlled";
        hbScore = "<5.7";
      } else {
        this.numbers.hba1cRiskcat = "moderate risk";
        hbScore = "else";
      }

      if (this.numbers.cholesterol >= 5) {
        this.numbers.cholesterolRiskcat = "high risk";
        cholScore = ">=5";
      } else if (this.numbers.cholesterol <= 3.5) {
        this.numbers.cholesterolRiskcat = "low risk";
        cholScore = "<=3.5";
      } else {
        this.numbers.cholesterolRiskcat = "moderate risk";
        cholScore = "else";
      }

      if (this.numbers.bmi >= 30) {
        this.numbers.bmiRiskcat = "high risk";
        bmiScore = ">=30";
      } else if (this.numbers.bmi < 25) {
        this.numbers.bmiRiskcat = "low risk";
        bmiScore = "<25";
      } else {
        this.numbers.bmiRiskcat = "moderate risk";
        bmiScore = "else";
      }

      if (this.numbers.SBP > 145 || this.numbers.DBP > 100) {
        this.numbers.bpRiskcat = "high risk";
        bpScore = ">145||>100";
      } else if (this.numbers.SBP <= 120 && this.numbers.DBP <= 90) {
        this.numbers.bpRiskcat = "low risk";
        bpScore = "<=120&<=90";
      } else {
        this.numbers.bpRiskcat = "moderate risk";
        bpScore = "else";
      }

      var cholRes = null;
      if (this.numbers.cholesterol != null) {
        cholRes = await QueryService.get_ActionMessage(
          "cholesterol",
          cholScore,
          this.numbers.cholesterolRiskcat
        );
      } else {
        cholRes = await QueryService.get_ActionMessage(
          "cholesterol",
          "none",
          "no readings"
        );
      }

      if (typeof cholRes != "undefined") {
        if (this.gender == "male")
          this.numbers.cholMessage = cholRes.data[0].message_male;
        else this.numbers.cholMessage = cholRes.data[0].message_female;

        this.numbers.cholesterolNurseTask = cholRes.data[0].nurse_task;
        this.numbers.cholesterolScreen = cholRes.data[0].screening_type;
      }

      var glucRes = null;
      if (this.numbers.bgRandom != null || this.numbers.bgFasting != null) {
        glucRes = await QueryService.get_ActionMessage(
          "glucose",
          glucoseScore,
          this.numbers.glucoseRiskcat
        );
      } else {
        glucRes = await QueryService.get_ActionMessage(
          "glucose",
          "none",
          "no readings"
        );
      }

      if (typeof glucRes != "undefined") {
        if (this.gender == "male")
          this.numbers.glucoseMessage = glucRes.data[0].message_male;
        else this.numbers.glucoseMessage = glucRes.data[0].message_female;

        this.numbers.bgNurseTask = glucRes.data[0].nurse_task;
        this.numbers.bgScreen = glucRes.data[0].screening_type;
      }

      var bmiRes = null;

      if (this.numbers.bmi != null) {
        bmiRes = await QueryService.get_ActionMessage(
          "bmi",
          bmiScore,
          this.numbers.bmiRiskcat
        );
      } else {
        bmiRes = await QueryService.get_ActionMessage(
          "bmi",
          "none",
          "no readings"
        );
      }

      if (typeof bmiRes != "undefined") {
        if (this.gender == "male")
          this.numbers.bmiMessage = bmiRes.data[0].message_male;
        else this.numbers.bmiMessage = bmiRes.data[0].message_female;

        this.numbers.bmiNurseTask = bmiRes.data[0].nurse_task;
        this.numbers.bmiScreen = bmiRes.data[0].screening_type;
      }

      var bpRes = null;
      if (this.numbers.SBP != null && this.numbers.DBP != null) {
        bpRes = await QueryService.get_ActionMessage(
          "blood pressure",
          bpScore,
          this.numbers.bpRiskcat
        );
      } else {
        bpRes = await QueryService.get_ActionMessage(
          "blood pressure",
          "none",
          "no readings"
        );
      }

      if (typeof bpRes != "undefined") {
        if (this.gender == "male")
          this.numbers.bpMessage = bpRes.data[0].message_male;
        else this.numbers.bpMessage = bpRes.data[0].message_female;

        this.numbers.bpNurseTask = bpRes.data[0].nurse_task;
        this.numbers.bpScreen = bpRes.data[0].screening_type;
      }

      var hb1acRes = null;
      hb1acRes = await QueryService.get_ActionMessage(
        "hb1ac",
        hbScore,
        this.numbers.hba1cRiskcat
      );
      console.log(hb1acRes);

      if (typeof hb1acRes != "undefined") {
        if (this.gender == "male")
          this.numbers.hba1cMessage = hb1acRes.data[0].message_male;
        else this.numbers.hba1cMessage = hb1acRes.data[0].message_female;

        this.numbers.hba1cNurseTask = hb1acRes.data[0].nurse_task;
        this.numbers.hba1cScreen = hb1acRes.data[0].screening_type;
      }
    },
  },
  computed: {
    computeBMI() {
      return Number(
        (
          this.numbers.weight /
          (this.numbers.height * this.numbers.height)
        ).toFixed(2)
      );
    },
  },
  created() {
    var visibleSections = this.$store.getters.getVisibleSections;
    if (visibleSections != null) {
      this.visible = visibleSections.numbers;
    }

    this.gender = this.$store.getters.getGender;
    var obj = this.$store.getters.getNumbersData;
    if (obj != null) {
      this.numbers = obj;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("saveNumbersData", this.numbers);
    next();
  },
};
</script>

<style scoped></style>
