<template>
  <div>
    <div class="columns customQuestion" v-if="type == '' || type == 'yes/no'">
      <div class="column is-three-quarters">
        <b-field expanded>
          <p>{{ index }}. {{ question }}</p>
        </b-field>
      </div>
      <div class="column">
        <b-field position="is-centered">
          <b-radio
            v-model="answer"
            :name="radioName"
            native-value="yes"
            required
            >yes</b-radio
          >
          <b-radio v-model="answer" :name="radioName" native-value="no" required
            >no</b-radio
          >
        </b-field>
      </div>
    </div>

    <div class="columns customQuestion" v-if="type == 'radio'">
      <div class="column is-three-quarters">
        <b-field expanded>
          <p>{{ index }}. {{ question }}</p>
        </b-field>
      </div>
      <div class="column radioField">
        <b-field position="is-centered">
          <b-radio
            v-for="(option, radioIndex) in radioOptions"
            :key="radioIndex + question"
            v-model="answer"
            :name="radioName"
            :native-value="option"
            :expanded="true"
            required
            >{{radioLabels[option]}}</b-radio
          >
        </b-field>
      </div>
    </div>

    <div class="columns customQuestion" v-if="type == 'dropdown'">
      <div class="column is-three-quarters">
        <b-field>
          <p>{{ index }}. {{ question }}</p>
        </b-field>
      </div>
      <div class="column is-one-quarter" style="text-align: center">
        <b-field position="is-centered">
          <b-select size="is-small" v-model="answer" :expanded="false" required>
            <option v-for="(option, index) in dropdownOptions" :key="index">
              {{ option }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns customQuestion" v-if="type == 'date'">
      <div class="column is-three-quarters">
        <b-field expanded>
          <p>{{ index }}. {{ question }}</p>
        </b-field>
      </div>
      <div class="column">
        <b-field position="is-centered">
          <b-input
            readonly
            size="is-small"
            :value="dateString(answer)"
          ></b-input>
          <b-datepicker
            :mobile-native="false"
            position="is-bottom-left"
            v-model="answer"
            size="is-small"
          >
            <template v-slot:trigger>
              <b-button
                icon-right="calendar"
                type="is-primary"
                size="is-small"
              ></b-button>
            </template>
          </b-datepicker>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answer: this.value,
    };
  },
  props: {
    index: Number,
    question: String,
    radioName: String,
    dataProperty: String, //for binding
    value: String | Boolean,
    type: String,
    dropdownOptions: Array,
    radioOptions: Array,
    radioLabels: null,
  },
  watch: {
    answer: function () {
      this.$emit("answered", this.answer, this.dataProperty, this.question);
    },
    value: function (newVal) {
      if (newVal == "" || newVal == null) {
        //if being cleared
        this.answer = null;
      }
    },
  },
  methods: {
    dateString(dateObj) {
      if (dateObj instanceof Date) {
        var dateArray = this.convertDateObject(dateObj);
        return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.customQuestion {
  padding-top: 10px;
  padding-bottom: 10px;
}

</style>
