

const state =  {
    hivData:null,
    tbData:null,
    stiData:null,
} 

const getters = {
    getHIVData:(state)=>{
        return state.hivData;
    },
    getTBData:(state)=>{
        return state.tbData;
    },
    getSTIData:(state)=>{
        return state.stiData;
    }

}

const actions = {
    saveHIVData(context, payload){
        context.commit('saveHIVData', payload)
    },
    saveTBData(context, payload){
        context.commit('saveTBData', payload)
    },
    saveSTIData(context, payload){
        context.commit('saveSTIData', payload)
    },
    clearHIV(context){
        context.commit('clearHIV')
    }

}

const mutations = {
    saveHIVData(state, payload){
        state.hivData = payload;
    },
    saveTBData(state, payload){
        state.tbData = payload;
    },
    saveSTIData(state, payload){
        state.stiData = payload;
    },
    clearHIV(state){
        state.hivData = null
        state.stiData = null
        state.tbData = null
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}